import img1 from "../../assets/splash1.png";
import img2 from "../../assets/splash2.png";
import img3 from "../../assets/splash3.png";
export const  items = [
    {
      name: "Welcome to DigiAqua",
      description:
        "The most convenient way to get a quality and vetted plumber (water technician) near you, for all your plumbing emergencies, water efficiency and water product installation needs.",
      imgPath: img1,
    },
    {
      name: "What we do ?",
      description:
        "Digi Aqua™ is a solution that connects plumbers to households at the shortest time possible. It contributes to the saving of water and money, by offering plumbing services through quality certified and vetted plumbers (water technicians), in a convenient way.",
      imgPath: img2,
    },
    {
      name: "How we do it ?",
      description:
        "The most convenient way to get a quality plumber (water technician) near you, in 5 easy steps:",
      body1: "Choose the service(s) you want and add it to the cart.",
      body2:
        "Request for a quotation and receive 3 estimated quotes from nearby water technicians.",
      body3: "Select the preferred quote.",
      body4:
        "After your preferred water technician has inspected and confirmed the quote, the job is attended to using quality material from reputable retailers [deposit payment is optional]",
      body5:
        "Once the job is done to your satisfaction, you can pay the outstanding amount, as per the agreed quote.",
      imgPath: img3,
    },
  ];