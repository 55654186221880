import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";

export default function UploadQualificationDocs(props) {
  const [count, setCount] = useState(0);
  const [imageArray, setImageArray] = useState([]);


  const onChange = (e) => {
    const files = e.target.files;
    imageArray.push(files[0].name);
    setCount(count + 1); 
  };
  function refreshArr(index) {
    let remoteNamex;
    const updatedArr = imageArray.filter((item, j) => {
      remoteNamex = item.remoteName;
      return j !== index;
    });
    setImageArray(updatedArr);
  }

  return (
    <>
      <Grid className="upload-qualification-container" m="2%">
            <Grid
              m={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <label htmlFor="uploadDoc_button">
                <div className="upload-qualification-btn">
                  <text>{props.name}</text>
                </div>
              </label>
              <input
                id="uploadDoc_button"
                // disabled={disabled}
                accept="image/*, application/pdf"
                type="file"
                name="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>

          {imageArray.map((obj, i) => {
            return (
              <Grid
                key={i}
                display="flex"
                justifyContent="space-between"
                borderRadius="5px"
                alignItems="center"
                bgcolor="#E1F5F8"
                p={1}
                mb={1}
              >
                <Typography
                  id="two-lines-text-css"
                  style={{ color: "#0d4f57" }}
                >
                  {obj}
                </Typography>
                <CloseIcon
                  onClick={() => refreshArr(i)}
                  style={{
                    padding: "3px",
                    borderRadius: "100px",
                    background: "white",
                    color: "#0d4f57",
                    border: "0.2px solid rgb(221, 221, 221)",
                    marginLeft:'10px'
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
  </>
  );
}
