// https://youtu.be/28ZbeLWmfiQ

import React, { useState, useRef } from "react";
import { Typography, Grid, Box } from "@mui/material";
import Button from "../../components/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import HeaderFooterHoc from "../../HOC/HeaderFooterHOC";
import "./styles.css";
import Badge from "@mui/material/Badge";
import { Line } from "react-chartjs-2";
import { useOnClickOutside } from './hooks';
import { items } from './CarouselData'
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  LineElement,
  Legend,
  PointElement,
  Filler,
} from "chart.js";

import StepperComp from "../LandingScreens/StepperComp";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

function Plumber() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  useOnClickOutside(node, () => setOpen(false));

  const data = {
    labels: [
      "Ja",
      "Fe",
      "Ma",
      "Ap",
      "Ma",
      "Ju",
      "Ju",
      "Au",
      "Se",
      "Oc",
      "no",
      "De",
    ],
    datasets: [
      {
        //  label: "",
        data: [30, 20, 19, 54, 40, 30, 43, 21, 67, 32],
        // backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: "#C4F4FF",
        tension: 0.4,
        // fill: true,
        borderWidth: 0.6,
        // pointBorderColor: "white",
        pointBorderWidth: 0,
        pointBackgroundColor: "#FE9400",
        pointRadius: 1.7,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: "#C4F4FF",
          borderWidth: 0.5,
        },
        ticks: {
          color: "#C4F4FF",
        },
      },
      y: {
        //  display: false,
        grid: {
          display: false,
          borderColor: "#C4F4FF",
          borderWidth: 0.5,
        },
        ticks: {
          color: "#C4F4FF",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };


  const handleProceed = () => {
    navigate("/PlumberDetailsNext")
  }
  return (

    <Grid

      style={{
        background: "#F4F4F4 0% 0% no-repeat padding-box",
      }}
    >


      <Grid
        padding={localStorage.getItem('login') === 'true' && localStorage.getItem('step2') === 'true' ? "6% 5% 2% 5%" : "0%"}
        style={{
          backgroundColor: "F2F2F2",
        }}
      >
        {localStorage.getItem('login') === 'true' && localStorage.getItem('step2') === 'true' ?
          (<>
            {localStorage.getItem('progress') === 'true' ?
              (
                <Grid
                  className="box1-plumber"
                  justifyContent="center"
                  padding="5%"
                  style={{}}
                >
                  <Typography
                    fontSize="16px"
                    fontFamily="Rubik"
                    fontWeight="Regular"
                    textAlign="center"
                    color="#000000"
                    letter-spacing="0.09px"
                    opacity="1"
                    paddingBottom='10px'
                  >
                    You Profile status
                  </Typography>
                  <StepperComp active={1} steps={['Profile created', 'Under Review', 'Approved']} />
                </Grid>) :
              (<Grid
                className="box1-plumber"
                justifyContent="center"
                padding="5%"
                style={{}}
              >
                <Typography
                  fontSize="16px"
                  fontFamily="Rubik"
                  fontWeight="Regular"
                  textAlign="center"
                  color="#000000"
                  letter-spacing="0.09px"
                  opacity="1"
                >
                  Become a verified member by <br />
                  completing the onboarding process
                </Typography>
                <Grid marginTop="5%" justifyContent="center" display="flex">
                  <Button color="green" name="Proceed" onClick={handleProceed} />
                </Grid>
              </Grid>)}
          </>) :
          (<div
            style={{
              height: "200px",
              paddingBottom: "50px",
            }}
            className="slide-container"
          >
            <Carousel activeIndicatorIconButtonProps={{ style: { color:'#0D5C75'} }} sx={{ paddingBottom: "20px" }}>
              {items.map((item, i) => (
                <Paper elevation={0} className="item">
                  <img
                    style={{
                      height: "220px",
                      width: "100%",
                    }}
                    alt=""
                    src={item.imgPath}
                  />
                  <div class="absolute-div">
                    {item?.body1 ? (
                      <div class="carousel-caption-body">
                        <h4>{item.name}</h4>
                        <Typography
                          style={{
                            lineHeight: "0.9",
                            padding: "5px 10px 5px 25px",
                            fontSize: "12px",
                          }}
                        >
                          {item.description}
                        </Typography>
                        <ul class="carousel-caption-para">
                          <li>{item?.body1}</li>
                          <li>{item?.body2}</li>
                          <li>{item?.body3}</li>
                          <li>{item?.body4}</li>
                          <li>{item?.body5}</li>
                        </ul>{" "}
                      </div>
                    ) : (
                      <div class="carousel-caption">
                        <h4>{item.name}</h4>
                        <Typography pt={1.5}>{item.description}</Typography>
                      </div>
                    )}
                  </div>
                </Paper>
              ))}
            </Carousel>
          </div>)}

        <Grid mt={3}>
          <Typography fontSize="16px">All your Jobs</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        pl={2}
        pt={3}
        pb={4}
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'scroll', padding: '5%', backgroundColor: "white", border: "1px solid #EAEAEA" }}
      >
        <div
          className="Job"
          onClick={() => {
            navigate("/allMatchingJobs", { state: "New" });
          }}
        >
          <Typography style={{ fontSize: "15px" }}>New</Typography>
          <div className="joblist" id="box-orange">
            <Badge
              style={{
                position: "absolute",
                right: "22%",
                top: "33%",
              }}
              badgeContent={1}
              color="orange"
            ></Badge>
            <text> 07</text>
          </div>
        </div>
        <div
          onClick={() => {
            navigate("/allMatchingJobs", { state: "In_Progress" });
          }}
          className="Job"
        >
          <Typography style={{ fontSize: "15px" }}>In Progress</Typography>
          <div className="joblist" id="box-blue">
            <Badge
              style={{
                position: "absolute",
                right: "22%",
                top: "33%",
              }}
              badgeContent={1}
              color="blue"
            ></Badge>
            <text> 07</text>
          </div>
        </div>
        <div className="Job"  onClick={() => {
            navigate("/allMatchingJobs", { state: "Completed" });
          }}>
          <Typography style={{ fontSize: "15px" }}>Completed</Typography>
          <div className="joblist" id="box-green">
            <Badge
              style={{
                position: "absolute",
                right: "22%",
                top: "33%",
              }}
              badgeContent={1}
              color="green"
            ></Badge>
            <text> 07</text>
          </div>
        </div>
        <div className="Job" onClick={() => {
            navigate("/allMatchingJobs", { state: "Cancelled" });
          }}>
          <Typography style={{ fontSize: "15px" }}>Cancelled</Typography>
          <div className="joblist" id="box-red">
            <Badge
              style={{
                position: "absolute",
                right: "22%",
                top: "33%",
              }}
              badgeContent={1}
              color="red"
            ></Badge>
            <text> 07</text>
          </div>
        </div>
      </Grid>
      <Grid
        className="viewJobs-box"
        onClick={() => {
          navigate("/allMatchingJobs", { state: "All Matching jobs" });
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
          }}
        >
          View all matching Jobs
        </Typography>

        <Badge badgeContent={12} color="primary"></Badge>

        <ArrowForwardIosIcon />
      </Grid>
      <Grid className="chartBox-plumber">
        <Grid
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="2vh"
        >
          <Grid
            style={{
              color: "white",
            }}
          >
            <Typography
              style={{
                fontSize: "15px",
                color: "#C8C8C8",
              }}
            >
              Total earning
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
              }}
            >
              Yearly Balance
            </Typography>
          </Grid>
          <Grid
            padding="10px 30px"
            borderRadius="12px"
            style={{
              background: "#CCE8EC 0% 0% no-repeat padding-box",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                textDecoration: "underline",
              }}
            >
              R 100
            </Typography>
          </Grid>
        </Grid>

        <div>
          <Line data={data} options={options} />
        </div>
      </Grid>
    </Grid>



  );
}

export default HeaderFooterHoc(Plumber, {
  // showFooter: true,
  // showHide: true,
  bodyClassname: "",
});
