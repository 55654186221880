import { configureStore } from '@reduxjs/toolkit';
import { ProductReducer } from '../../reducer/productReducer';

export const store = configureStore({
    reducer: {
        [ProductReducer.reducerPath]: ProductReducer.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        ProductReducer.middleware),
});

export default store;
