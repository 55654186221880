import React from "react";
import "./styles.css";
import { Typography, Grid } from "@mui/material";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";

export default function Card(props) {
  const { showTitle, headerTitle, data, profile } = props;
  const quoteStatus = data?.quotes? data?.quotes[0]?.status : null;
  const jobStatus = data?.status ?  data?.status : null;
  const navigate = useNavigate();
  function viewDetailsFunc(data) {

    let stateObj = {
      tabVal: 0,
      jobStatus: jobStatus,
      quoteStatus: quoteStatus,
      id: data.id,
      headerTitle: headerTitle,
    };
    
    if (headerTitle?.includes("All Matching jobs")) {
      navigate("/jobDetails", { state: stateObj });
    } else {
      navigate("/Job-quoteCal", { state: stateObj });
      
    }
  }
  function viewProfileFunc(data) {
    let stateObj = {
      id: data.id,
      headerTitle: headerTitle,
    };
    navigate("/jobDetails", { state: stateObj });
  }
  function DateConverter(data) {
    const date = moment(data).format("L");
    return date;
  }
  function TimeConverter(data) {
    const date = moment(data).format("LT");
    return date;
  }

  function trimmedStatus(status) {
    if (status) {
      const result = status.split(/(?=[A-Z])/);
      return result[1];
    }
  }

  function changedColrFunc(status) {
    let colorVal =
      trimmedStatus(status)?.toUpperCase() === "REJECTED"
        ? "#B00020"
        : trimmedStatus(status)?.toUpperCase() === "APPROVED"
        ? "#009B2A"
        : trimmedStatus(status)?.toUpperCase() === "SUBMITTED"
        ? "#008EA1"
        : trimmedStatus(status)?.toUpperCase() === "REVISION"
        ? "#0D5C75"
        : "#000";

    return colorVal;
  }

  return (
    <Grid>
      {showTitle && (
        <Typography
          pt={2}
          color="#888888"
          style={{
            fontSize: "13px",
          }}
        >
          Job no #{data?.id}
        </Typography>
      )}

      <Grid
        style={{
          background: "#ffffff",
        }}
        className="box-matchingJobs-card"
      >
        <Grid className="card-img-section">
          <Grid>
            <img className="card-img" src={data?.owner?.ProfileUrl} alt="" />
          </Grid>
          <Typography
            id="one-lines-text-css"
            maxWidth="80%"
            textAlign="center"
            style={{
              marginTop: "7px",
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "1.2",
            }}
          >
            {data?.owner?.firstname}
          </Typography>
          <Typography
            textAlign="center"
            style={{
              fontSize: "12px",
              color: "#676767",
            }}
            id="one-lines-text-css"
          >
            {" "}
            {data?.owner?.LocationName}
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
            }}
          >
            {data?.owner?.distance}
          </Typography>
        </Grid>
        <Grid width="70%" position="relative" pl={1} pt={2} pr={2} pb={2}>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="#888888"
          >
            <Typography
              display="flex"
              alignItems="center"
              style={{
                fontSize: "14px",
              }}
            >
              Rating :{" "}
              <span style={{ color: "black", padding: "0px 5px" }}>
                {" "}
                {data?.owner?.ratings}
              </span>
              <StarIcon
                style={{
                  fontSize: "18",
                  color: "#0d4f57",
                }}
              />{" "}
            </Typography>

            <Typography
              style={{
                fontSize: "14px",
              }}
            >
              Reviews :{" "}
              <span style={{ color: "black" }}>{data?.owner?.reviews}</span>
            </Typography>
          </Grid>

          <Grid container mb={0.8} mt={0.7}>
            <Grid item xs={3.5}>
              <Typography
                // fontWeight="bold"
                style={{
                  fontSize: "14px",
                  lineHeight: "1.2",
                  color: "#0D5C75",
                }}
              >
                {" "}
                Date &{"\n"} Time{" "}
              </Typography>
            </Grid>
            <Grid
              xs={8.5}
              // border="1px solid green"
              display="flex"
              alignItems="center"
              item
            >
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "1.2",
                }}
              >
                : {DateConverter(data?.date)}
                <span
                  style={{
                    borderLeft: "1.2px solid #3C8C9E",
                    paddingLeft: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {TimeConverter(data?.time)}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Typography
            id="one-lines-text-css"
            color="rgba(114, 114, 114, 1)"
            style={{
              fontSize: "13px",
              lineHeight: "1.2",
            }}
          >
            Looking for : <span style={{ color: "black" }}> {data?.title}</span>
          </Typography>
          {profile ? (
            <>
              {" "}
              <Grid mt={1.5}>
                <Button
                  name="View Profile"
                  onClick={() => {
                    viewProfileFunc(data);
                  }}
                  color="green"
                />
              </Grid>
            </>
          ) : (
            <>
              {" "}
              <Grid mt={1.5}>
                <Button
                  name="View Details"
                  onClick={() => {
                    viewDetailsFunc(data);
                  }}
                  color="green"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {quoteStatus && (
        <Grid
          pt={1}
          pl={2}
          pr={2}
          pb={1}
          display="flex"
          justifyContent="space-between;"
          alignItems="center"
          sx={{
            background: "#E5F5E9 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 6px #BABABA29",
            borderRadius: "0px 0px 3px 3px",
          }}
        >
          <div>Quote Amount</div>
          <div
            style={{
              backgroundColor: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              fontWeight: "bold",
              color: "#0D5C75",
            }}
          >
            R {data?.quotes?.[0]?.amount}
          </div>
          <div style={{ fontWeight: "bold", color: changedColrFunc(data?.quotes?.[0]?.status) }}>
            {trimmedStatus(data?.quotes?.[0]?.status)}
          </div>
        </Grid>
      )}
    </Grid>
  );
}
