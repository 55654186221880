import { Camera, CameraResultType } from "@capacitor/camera";

export const FromCamera = async () => {
  console.log("camera");
  const image = await Camera.getPhoto({
    quality: 90,
    //   allowEditing: true,
    // resultType: CameraResultType.Base64,
    resultType: CameraResultType.Uri,
  });
  // var blob = image.webPath; // webPath contains blob url
  // let blobUrl = URL.createObjectURL(blob);
  return image.webPath;
};

export const FromGallery = async () => {
  console.log("gallery");
  const image = await Camera.pickImages({
    quality: 90,
    //   allowEditing: true,
    resultType: CameraResultType.Uri,
  });
  var imageUrl = image.webPath;
  console.log("imageWebpath - ", imageUrl);
};
