import { TextField } from "@mui/material";
import "./styles.css";

function TextFieldApp(props) {
  const {
    dimentions,
    listTXFL,
    formValues,
    handleChange,
    InputProps,
    InputLabelProps,
    textFieldStyle,
    disabled,
    labelFontSize,
    inputProps
  } = props;

  


  return (
    <div className="textHeader">
      {listTXFL.map((item) => (
        <>
          <div
            className={labelFontSize ? "personalDetailsLableCls" : "lableCls"}
          >
            {item.label}
          </div>

          <TextField
            sx={{ fontSize: "0.8rem", width: "100%" }}
            className="text_field"
            key={item.label}
            style={textFieldStyle}
            variant="outlined"
            placeholder={item.plcHol}
            name={item.name}
            type={item.type}
            margin="dense"
            disabled={disabled}
            value={
              item.name
                ? formValues && formValues[item.name]
                : formValues && formValues[item.type]
            }
            onChange={(e) => {
              handleChange(e);
            }}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
          />
        </>
      ))}
    </div>
  );
}

export default TextFieldApp;
