import baseAPI from "../app/baseAPI";

export const ViewJobReducer = baseAPI.injectEndpoints({
  endpoints(builder) {
    return {
      fetchViewJobDetails: builder.query({
        query(jobStatus) {
          // let url = null;
          // if(jobStatus?.firstStatus && jobStatus?.secondStatus) {
          //   url = `http://localhost:3000/jobs?status=${jobStatus.firstStatus}&status=${jobStatus.secondStatus}`;
          // }else if (jobStatus?.firstStatus) {
          //   url = `http://localhost:3000/jobs?status=${jobStatus.firstStatus}`;

          // }else if (jobStatus?.secondStatus) {
          //   url = `http://localhost:3000/jobs?status=${jobStatus.secondStatus}`;
          // }
          return {
            url: `http://localhost:3000/jobs`,
            method: "GET",
            token: true,
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
      fetchViewJobDetailsbyId: builder.query({
        query(id) {
          return {
            url: `http://localhost:3000/jobs/${id}`,
            method: "GET",
            token: true,
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
      updateJobStatus: builder.mutation({
        query(params) {
          return {
            url: `http://localhost:3000/jobs/` + params.Id,
            method: "PATCH",
            token: true,
            body: {
              ...params.paramsData,
            },
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
    };
  },
});
export const {
  useFetchViewJobDetailsQuery,useUpdateJobStatusMutation,useFetchViewJobDetailsbyIdQuery
} = ViewJobReducer;