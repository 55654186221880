import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: top;
  background: ${({ theme }) => theme.hamberbackround};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 75%;
    }

    profilecard {
      background: linear-gradient(#0D5C75, #042733);
      height: 7rem;
      display: flex;
      position:relative;
    }

  a {
    font-size: 2rem;

    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: ${({ theme }) => theme.menuitemcolor
  };
   

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 15px;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`;
