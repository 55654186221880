import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./styles.css";

const HeaderFooterHoc = (WrappedComponent, selectData) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        // isShow: selectData?.isShow || false,
        // showHide: selectData?.showHide || false,
        // showFooter: selectData?.showFooter || false,
        // getMunicipalityID: null,
      };
    }

    // getAccessMunicipalityArticle = (value) => {
    //   console.log(value);
    //   this.setState({ getMunicipalityID: value });
    // };

    render() {
      const { isShow, showHide, heightofMainConainer, showFooter } = this.state;
      const { bodyClassname } = selectData;

      return (
        <>
          <Header
          // getAccessMunicipalityArticle={this.getAccessMunicipalityArticle}
          // showHide={showHide}
          // Mycomponent={selectData?.Mycomponent}
          />

          {/* {isShow ? <AppLogo logoStyle="logoStyle_myProfile" /> : null} */}
          <div className={`custom-body ${bodyClassname || " "}`}>
            <WrappedComponent {...this.state} />
          </div>
          {/* {showFooter ?  */}
          {/* <Footer /> */}
          {/* : null} */}
        </>
      );
    }
  }
  return HOC;
};

export default HeaderFooterHoc;
