import { React, useState } from "react";
import { Typography, Grid, FormControl, TextField, Icon, Button, Checkbox } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { InputAdornment } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "react-select";
import Clock from "@mui/icons-material/AccessTime";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./style.css";
import UploadQualificationDocs from "./UploadqualificationDocs";
import MultiSelectDropDown from "./MultiSelectDropDown";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import defaultProfile from "../../assets/profilePic.jpg";
import ButtonComp from '../../components/Button/index.js'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HomeHeader from "../../components/HomeHeader";
import FormControlLabel from '@mui/material/FormControlLabel';
import StepperComp from "./StepperComp";
import { useNavigate } from "react-router-dom";

export default function PlumberDetails() {
    const weekDays = [
        { id: 1, name: "Sun", isClicked: false },
        { id: 2, name: "Mon", isClicked: false },
        { id: 3, name: "Tue", isClicked: false },
        { id: 4, name: "Wed", isClicked: false },
        { id: 5, name: "Thu", isClicked: false },
        { id: 6, name: "Fri", isClicked: false },
        { id: 7, name: "Sat", isClicked: false },
    ];

    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [city, setCity] = useState('');
    const [profilePic, setProfilePic] = useState(defaultProfile);
    const [openPopup, setOpenPopup] = useState(false);
    const [popuptitle, SetPopupTitle] = useState("");
    const [popupContent, SetPopupContent] = useState("");
    const [buttonArr, setButtonArr] = useState([]);
    const [province, setProvince] = useState('');
    const [weekDay, setWeekDay] = useState(weekDays);
    const [values, setValues] = useState();
    const navigate = useNavigate();

    const cityList = [
        { value: "Delhi", label: "Delhi" },
        { value: "Mumbai", label: "Mumbai" },
        { value: "Bangalore", label: "Bangalore" },
    ];
    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };
    const handleButtonClick = () => {
        localStorage.setItem('step2', true)
        navigate("/Plumber")
    }
    function customTheme(theme) {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#F2F2F2",
                primary: "#72727233",
            },
        };
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "none",
            color: state.isSelected ? "#ffffff" : "black",
            // padding: "3px",
            // fontSize: "13px",
            backgroundColor: state.isSelected ? "#0d4f57" : "white",
        }),
        control: () => ({
            display: "flex",
            border: "1.5px solid #3c8c9e",
            backgroundColor: "none",
            borderRadius: "3px",
            padding: "4px 0px",
            // minHeight: "31px",
        }),
        input: (provided) => ({
            ...provided,
        }),
        singleValue: (provided) => ({
            ...provided,
            // fontSize: "13px",
            color: "#000000",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: "none",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "rgba(0, 0, 0, 0.25)",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "#0D5C75",
            // backgroundColor: "red",
            // padding:'10px'
        }),
        valueContainer: (provided) => ({
            ...provided,
        }),
    };
    const handleClick = (i) => {
        let selectedWeekDay = weekDay[i].isClicked ? { ...weekDay[i], isClicked: false } : { ...weekDay[i], isClicked: true }
        var foundIndex = weekDay.findIndex(x => x.id === selectedWeekDay.id);
        weekDay[foundIndex] = selectedWeekDay
        setWeekDay([...weekDay])
    }
    const handleProfilePicChange = (e) => {
        const files = e.target.files;
        setProfilePic(URL.createObjectURL(files[0]));
    };

    function deleteProfilePic() {
        setOpenPopup(false);
    }
    const handleProfileDeletetion = () => {
        setOpenPopup(true);
        setProfilePic(defaultProfile);
        SetPopupTitle("Photo Removed");
        SetPopupContent("Successfully removed the profile photo");
        let btnValues = [
            {
                id: "1",
                value: "Ok",
                fontWeight: "bold",
                color: "borderLess",
                showCondition: "true",
                functionCall: deleteProfilePic,
            },
        ];
        setButtonArr(btnValues);
    };
    console.log(weekDay)
    return (
        <>
            <Grid container backgroundColor='#F4F4F4' className='main-container' >
                <HomeHeader header="Edit Profile" />
                <Grid className="card-img-section" style={{ padding: "7%", backgroundColor: '#ffffff', width: '100%' }}>
                    <Grid className="img-container">
                        <img
                            src={profilePic}
                            style={{ height: "100px", width: "100px", borderRadius: '10%' }}
                            alt=""
                        />
                        <div
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <label htmlFor="uploadDoc_icon">
                                <Icon className="picture">
                                    <CameraAltIcon />
                                </Icon>
                            </label>
                            <input
                                id="uploadDoc_icon"
                                accept="image/*"
                                type="file"
                                name="file"
                                onChange={(e) => handleProfilePicChange(e)}
                            />

                            <Icon onClick={handleProfileDeletetion} className="delete">
                                <DeleteOutlineIcon />
                            </Icon>
                        </div>
                    </Grid>
                    <Grid className="typography">
                        <Typography style={{ fontSize: "15px", top: "80px", }} color="#000000">
                            Hi, Name
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container display='flex' backgroundColor='#F4F4F4' height='5%' style={{ alignItems: 'center', padding: '20px 90px 35px 90px', position: 'sticky', top: 0, zIndex: 1 }}>
                    <StepperComp active={1} steps={['step 1', 'Step 2']} />
                </Grid>
                <Grid container display='flex' height='2%' padding='0px 14px 18px 20px' style={{ justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: '600' }} fontSize='20px'>Tell us more about you!</Typography>
                    <Typography>Step 1</Typography></Grid>

                <Grid padding="7%" container backgroundColor='#FFFFFF' marginBottom="25px">
                    <FormControl size="small" fullWidth>
                        <Grid item className="">
                            <div className="addressCls">Profile Details</div>
                        </Grid>

                        <Grid item sm={6} pt={1} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                What is your first name*?
                            </label>
                            <TextField
                                size="small"
                                placeholder="Add first name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                What is your last name*?
                            </label>
                            <TextField size="small" placeholder="Add last name" fullWidth />
                        </Grid>
                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                What is your email ID*?
                            </label>
                            <TextField size="small" placeholder="Enter email ID" fullWidth helperText="We promise not to spam you!" />
                        </Grid>
                        <Grid item sm={6} pb={1} lg={12} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                Mobile Number
                            </label>
                            <TextField
                                size="small"
                                //value={values.mobileNo}
                                //error={!isMobileNoValid}
                                // helperText={
                                //   !isMobileNoValid ? "Please enter valid Mobile No." : " "
                                // }
                                onChange={handleChange("mobileNo")}
                                placeholder="Enter Mobile Number"
                                fullWidth
                                className="textBox"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start"> +27 |</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="name" className="labelCls2"> Select your Gender </label>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: '16px' }}>
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </div>
                            </RadioGroup>
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid padding="7%" container backgroundColor='#FFFFFF' marginBottom="25px">
                    <Grid display="flex" flexDirection="column"><Typography style={{ fontSize: '16px', letterSpacing: '0.1px' }}>Work days & hours</Typography>
                        <Typography style={{ fontSize: '12px', letterSpacing: '0.07px', margin: '15px 0px 10px 0px' }}>Add your work time*</Typography></Grid>
                    {/* <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    {weekDay.map((day, i) => {
                        return (<Button variant="outlined" size="small" className="button-week" style={{ backgroundColor: day.isClicked ? '#CCE8EC' : '#ffffff' }} onClick={() => handleClick(i)}>{day.name}</Button>)
                    })}
                </Grid> */}
                    {/* <Typography style={{ fontSize: '12px', letterSpacing: '0.07px', margin: '25px 0px 10px 0' }}>Add your work time*</Typography> */}
                    <Grid style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                className="timePicker"
                                value={startTime}
                                onChange={(newValue) => {
                                    setStartTime(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={new Date()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" size="small">
                                                    <Clock
                                                        style={{
                                                            fontSize: 29,
                                                            color: "#0D5C75",
                                                            paddingLeft: "10px",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                value={endTime}
                                onChange={(newValue) => {
                                    setEndTime(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={new Date()}
                                        size="5px"
                                        sx={
                                            {
                                                // padding: "-5px",
                                            }
                                        }
                                        style={
                                            {
                                                // padding: "-5px",
                                            }
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" size="small">
                                                    <Clock
                                                        style={{
                                                            fontSize: 29,
                                                            color: "#0D5C75",
                                                            paddingLeft: "10px",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            style: { fontSize: 16 },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid padding="7%" container backgroundColor='#FFFFFF' marginBottom="25px">
                    <FormControl size="small" fullWidth>
                        <Grid item className="">
                            <div className="addressCls">Add Address</div>
                        </Grid>

                        <Grid item sm={6} pt={1} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                House /Apartment Number*
                            </label>
                            <TextField
                                size="small"
                                placeholder="Add house Number"
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                Landmark*
                            </label>
                            <TextField size="small" placeholder="Housename" fullWidth />
                        </Grid>
                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="Province" className="labelCls2">
                                Street / Area Name*
                            </label>
                            <TextField size="small" placeholder="Housename" fullWidth />
                        </Grid>
                        <div
                            style={{
                                paddingTop: "20px",
                            }}
                        >
                            <label htmlFor="Province" className="labelCls2">
                                Select City
                            </label>
                            <Select
                                placeholder="Select city"
                                // defaultValue={plist[index]}
                                options={cityList}
                                theme={customTheme}
                                styles={customStyles}
                                isSearchable={false}
                                value={city}
                                onChange={(val) =>
                                    setCity(val)
                                }
                            />
                        </div>
                        <div
                            style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                            }}
                        >
                            <label htmlFor="Province" className="labelCls2">
                                Select Province*
                            </label>
                            <Select
                                placeholder="Select province"
                                // defaultValue={plist[index]}
                                options={cityList}
                                theme={customTheme}
                                styles={customStyles}
                                isSearchable={false}
                                value={province}
                                onChange={(val) =>
                                    setProvince(val)
                                }
                            /></div>
                    </FormControl>
                </Grid>
                <Grid padding="7%" container backgroundColor='#FFFFFF' marginBottom="25px">
                    <FormControl size="small" fullWidth>
                        <Grid item >
                            <div className="addressCls">Qualification & Skills</div>
                        </Grid>
                        <div style={{ paddingTop: '7px' }}>
                            <label htmlFor="Province" className="labelCls2">
                                What are your skills*?
                            </label>
                            <MultiSelectDropDown />
                        </div>

                        <Grid item sm={6} pt={3} className="grid">
                            <label htmlFor="name" className="labelCls2">
                                What is your qualification*
                            </label>
                            <TextField size="small" placeholder="Enter Qualification details" fullWidth helperText="File size can be Max 5 mb" />
                        </Grid>
                        <UploadQualificationDocs name="Upload Qualification docs" />
                        <Typography align="center" style={{ 'marginTop': '-15px' }}><a href="" style={{ color: '#0D5C75' }}>I will upload later</a> </Typography>
                    </FormControl>
                </Grid>
                <Grid display="flex" flexDirection="row" alignItems="center" justifyContent="center" bgcolor="#ffffff" padding="12px 20px 12px 20px" width="100%" sx={{ position: 'sticky', bottom: 0, boxShadow: 8 }}>
                    <ButtonComp
                        color="green"
                        name="Save Details"
                        onClick={handleButtonClick}
                    />
                </Grid>
            </Grid>
        </>
    );
}
