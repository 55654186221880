import axios from "axios";

const getApiResponse = async ({ method, url, token, body }) => {
  let request = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
  };
  // const tokens = await getToken();
  request = body ? { ...request, data: { ...body } } : request;

  request = token
    ? {
        ...request,
        headers: { ...request.headers},
      }
    : request;
  return axios({
    ...request,
  });
};

export const getResponseData = async (Payload) => {
  try {
    const getdata = await getApiResponse(Payload);
    return getdata.data;
  } catch (e) {
    return e?.status;
  }
};

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async (payload) => {
    const { url } = payload;
    const payloads = { ...payload, url: url };
    // const payloads = { ...payload, url: baseUrl + url };
    try {
      const result = await getApiResponse(payloads);
      if (result?.data && result?.data?.error) {
        const error = {
          status: 200,
          data: result?.data?.error,
        };
        throw error;
      } else {
        return { data: result.data };
      }
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: axiosError?.status,
          data: axiosError.data?.errorMessage,
        },
      };
    }
  };
