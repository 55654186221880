import React, { useState } from "react";
import "./styles.css";
import { Grid, Typography, TextField, InputAdornment, Link } from "@mui/material";
import DialogComp from "../../components/Dialog-Popup";
import "react-spring-bottom-sheet/dist/style.css";
import Button from "../../components/Button";
import loginimage from "../../assets/loginbackground.jpg";
import image1 from "../../assets/loginmiddle1.png";
import image2 from "../../assets/logindotted.png";
import { useNavigate } from "react-router-dom";

function Login() {
  const [isHidden, setHidden] = useState(true)
  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [otp, setOtp] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);
  const navigate = useNavigate();

  function proceedFunc() {
    setHidden(false)
    if (!isHidden) {
      setOpenPopup(true);
      SetPopupTitle("Logged in successfully");
      SetPopupContent("You've logged in to the portal successfully.Please fill some of the details to get started."
      );
      let btnValues = [
        {
          id: "1",
          value: "PROCEED",
          color: "borderLess",
          fontSize: "16px",
          functionCall: closeFunc,
        },
      ];
      setButtonArr(btnValues);
    }
  }
  function closeFunc() {
    setOpenPopup(false);
    navigate("/plumberDetails")
  }

 
  const handleChange = (event) => {
    setOtp(event.target.value);
  };

  return (
    <Grid container className="login_container" style={{ backgroundImage: `url(${loginimage})`, height: "100vh", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} >
      <div style={{ position: 'relative', marginTop: "78%", marginLeft: '5%' }}>
        <div style={{ position: 'absolute' }}>

          <img src={image1} style={{ backgroundColor: "none", marginLeft: '33%', marginTop: "22%" }} alt="hiii"

          />
        </div>
        <div >
          <img src={image2} style={{ backgroundColor: "none", marginLeft: '124%' }} alt="hiii" />
        </div>
      </div>
      {isHidden ? (
        <div style={{ paddingLeft: "15%", paddingRight: "1rem" }}>
          <Typography
            style={{
              display: "flex", flexDirection: "row", marginBottom: "12%",
              fontSize: "1rem", fontWeight: '600', letterSpacing: '1', color: '#0D5C75'
            }}
          >
            <span style={{ fontWeight: "bold" }}> Login/Sign up &nbsp;</span>to continue
          </Typography>
          <TextField
            id="outlined-required"
            label="Provide Your mobile number"
            defaultValue=""
            name="lname" fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> +91| </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: '#0D5C75',fontFamily: 'Rubik', fontSize:'95%' },
            }}
            size="small"
          />
          <Typography style={{ color: '#0D5C75', fontSize: "70%" }}>
            OTP will be send to the given Mobile number
          </Typography>

        </div>) : (

        <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
          <Typography
            style={{
              display: "flex", flexDirection: "row", marginBottom: "5%",
              fontSize: "15px", fontWeight: '600', letterSpacing: '1', color: '#0D5C75'
            }}
          >
            Please enter verification code (OTP) sent to your mobile number.
          </Typography>
          <TextField
            value={otp}
            fullWidth
            sx={{ backgroundColor: 'red' }}
            size="small"
            id="textchangestyle"
            onChange={handleChange}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: "5%" }}>
            <Link href="#">Resend<span style={{ fontWeight: "bold" }}> OTP</span></Link>
            {/* <span style={{fontWeight:"bold"}}> Login/Sign up &nbsp;</span>to continue */}
            <Typography>
              00:05
            </Typography>
          </div>
        </div>)}
      <DialogComp
        openPopup={openPopup}
        popuptitle={popuptitle}
        popupContent={popupContent}
        buttonValue={buttonArr}
      />

      <Grid display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%" sx={{ paddingBottom: isHidden ? "5%" : "30%" }}>
        <Button
          color="green"
          name={isHidden ? "Continue" : "Confirm OTP"}
          onClick={proceedFunc}
        ></Button>
      </Grid>
    </Grid>
  );
}

export default Login;
