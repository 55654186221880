import * as React from "react";
import "./styles.css";
import { Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@material-ui/core";

export default function PaymentDetailsBottomSheetFun(props) {
  const { closeBtmSheet,paymentValues} = props;

  return (
    <Grid margin="5%">
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <div className="paymentDetails-DivCls">Payment Details</div>
        <CloseIcon
          onClick={() => {
            closeBtmSheet();
          }}
          style={{           
            color: "#0D5C75",
            zIndex:'5',
          }}
        />
      </Grid>

      <Grid container>
        <Grid
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          borderRadius="5px"
        >
          
          <Grid container spacing={2} pt={2} pb={2}>
            <Grid item xs={5}>
              <span className="paymentTitleCls">TOTAL Summary </span>
            </Grid>
            <Grid item xs={1}>
              <span>: </span>
            </Grid>
            <Grid item xs={4}>
              <span className="paymentValueCls">R {paymentValues.total}</span>
            </Grid>

            <Grid item xs={5}>
              <span className="paymentTitleCls">Partial payment done </span>
              <div className="dateCntCls">On {paymentValues.date}</div>
            </Grid>
            <Grid item xs={1}>
              <span>: </span>
            </Grid>
            <Grid item xs={4}>
              <span className="paymentValueCls">R {paymentValues.partialpay}</span>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={5}>
              <span className="paymentTitleCls">Payment Mode </span>
            </Grid>
            <Grid item xs={1}>
              <span className="paymentTitleCls">: </span>
            </Grid>
            <Grid item xs={4}>
              <span className="paymentTitleCls">{paymentValues.paymentmode}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
