import * as React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate} from "react-router-dom";

function Setting() {
    const navigate = useNavigate();
    return (

        <Grid sx={{ height: '100%' }} >
            <Grid style={{ display: "flex", flexDirection: 'start', padding: '1rem', paddingLeft: '1rem', position: "sticky", top: "0", backgroundColor: '#F2F2F2', width: '100%', zIndex: '1' }}>
                <ArrowBackIcon
                    onClick={() => {
                        navigate("/Plumber");
                      }}
                    style={{ fontSize: "31", color: "#0d4f57", paddingLeft: "3%", justifyContent: "left", }} />
                <Typography
                    pl={2}
                    style={{
                        fontSize: "17px", fontWeight: '600', letterSpacing: '1', color: '#535353', paddingLeft: '15px'
                    }}
                >
                    Settings
                </Typography>

            </Grid>
            <Grid>
                <Typography onClick={() => console.log('clicked')}

                    style={{
                        fontSize: "1.2", opacity: '1', fontWeight: '600', marginTop: '5%', letterSpacing: '0.09', color: '#0D5C75', paddingLeft: '8%'
                    }}
                >
                    Delete Account
                </Typography>
            </Grid>



        </Grid>

    );
}
export default Setting;
