import { React, useState } from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@mui/material";
import Card from "../JobCard";
import moment from "moment";
import Moment from "react-moment";
import "./style.css";

export default function JobDetailsFun(props) {
  const { JobDetailsData } = props;
  function DateConverter(data) {
    const date = moment(data).format("D MMM YYYY");
    return date;
  }
  function TimeConverter(data) {
    const date = moment(data).format("LT");
    return date;
  }
  function IsRecurring(data) {
    return data === 1 ? "Yes" : "No";
  }
  function isEmergency(data) {
    return data === 1 ? "Yes" : "No";
  }

  const _ = require("lodash");
  const groups = _.groupBy(JobDetailsData?.products, "category");
  let categoryServiceArr = Object.entries(groups)?.map((item) => {
    let makeObj = {
      category: item[0],
      services: item[1],
    };
    return makeObj;
  });

  return (
    <Grid container padding="4%">
      <div className="jobDetHeadCls">Job details</div>

      <Grid container>
        {categoryServiceArr?.map((prod, i) => {
          return (
            <>
              {" "}
              <Grid
                item
                xs={12}
                sx={{ p: 2 }}
                className="main-box-viewJobs-card"
              >
                <div className="labelCls">Job Category</div>
                <div className="valueCls">{prod?.category}</div>
                <br /> <div className="labelCls">Service</div>
                {prod?.services?.map((item, i) => {
                  return (
                    <>
                      {" "}
                      <div
                        className="valueCls"
                        style={{ paddingBottom: "5px" }}
                      >
                        {item?.title}
                      </div>
                    </>
                  );
                })}
              </Grid>
            </>
          );
        })}

        <Grid item xs={12} sx={{ p: 2 }} className="main-box-viewJobs-card">
          <Grid>
            <div className="">
              <div className="labelCls">Is this an emergency</div>
              <div className="valueCls">
                {isEmergency(JobDetailsData?.isEmergency)}
              </div>
            </div>
            <div className="labelDivCls">
              <div className="labelCls">Job Date</div>
              <div className="valueCls">
                {DateConverter(JobDetailsData?.date)}{" "}
              </div>
            </div>
            <div className="labelDivCls">
              <div className="labelCls">Selected Time</div>
              <div className="valueCls">
                {TimeConverter(JobDetailsData?.time)}{" "}
              </div>
            </div>
            <div className="labelDivCls">
              <div className="labelCls">Is this is a recurring issue</div>
              <div className="valueCls">
                {IsRecurring(JobDetailsData?.isRecurring)}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
