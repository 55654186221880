import { React, useState } from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@mui/material";
import Card from "../../components/JobCard";
import JobDetailComp from "../../components/JobDetailComp";
import Button from "../../components/Button";
import DialogComp from "../../components/Dialog-Popup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { useFetchViewJobDetailsbyIdQuery } from "../../reducer/viewJobReducer";
import HomeHeader from "../../components/HomeHeader";
import "./style.css";
import { getAppState, getNextJobState, getNextQuoteState, QuoteBTNs, JobBTNs, JobState } from "../../businesslogic"

export default function JobDetailsFun(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state?.id;
  let jobStatus = null, quoteStatus = null;
  const { data: jobDetails } = useFetchViewJobDetailsbyIdQuery(item, {
    skip: !item,
  });
  console.log("job detail", jobDetails);
  jobStatus = jobDetails?.status;

  const appState = getAppState(jobStatus, quoteStatus);
  console.log("App State", appState);


  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);
  const [btmSht, setbtmSht] = useState(false);


  var btnEvntHandlerMap = new Map();
  btnEvntHandlerMap.set(JobBTNs.RespondToJob, respondToJob_EH);


  function respondToJob_EH(buttonName) {
    appState.action = buttonName;
    respondFunc();
    // navigate("/rescheduleJob", { state: data });
  }

  function closeFunc() {
    setOpenPopup(false);
  }

  function respondFunc() {
    setOpenPopup(true);
    SetPopupTitle("Great !");
    SetPopupContent(
      "You are trying to Respond to the service request.Please proceed with the Quote calculation."
    );
    let btnValues = [
      {
        id: "1",
        value: "Proceed",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: confirmFunc,
      },
    ];
    setButtonArr(btnValues);
  }

  function confirmFunc() {
    setOpenPopup(false);
    let stateObj = {
      tabVal: 1,
      jobStatus: jobStatus,
      quoteStatus: null,
      id: jobDetails.id,
    };
    navigate("/Job-quoteCal", { state: stateObj });
  }

  return (
    <Grid>
      <Grid container padding="4%">
        <Grid padding="1%" display="flex" alignItems="center">
          {/* <ArrowBackIcon
            color="primary"
            style={{
              fontSize: "29",
              marginRight: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="">
            <Typography color="">Job no  #{jobDetails?.id}</Typography>
          </div> */}
          <HomeHeader header={<>Job no #{jobDetails?.id}</>} />
        </Grid>

        <Grid padding="4%" style={{ width: "100%" }}>
          {/* {SampleData.map((obj, i) => {
            return (
              <> */}

          <Card profile={true} data={jobDetails} showTitle={false} />
          {/* </>
            );
          })} */}
        </Grid>

        <JobDetailComp
          JobDetailsData={jobDetails}
        // data={jobDetails}
        />
        <Grid container sx={{ p: "4%", mt: "-30px" }}>
          <Grid item xs={12} sx={{ p: 2 }} className="main-box-viewJobs-card">
            <Grid>
              <div className="labelDivCls">
                <div className="labelCls">Property Type</div>
                <div className="valueCls">{jobDetails?.propertyType}</div>
              </div>
              <div className="labelDivCls">
                <div className="labelCls">Location/Address</div>
                <div className="valueCls">
                  {jobDetails?.address?.houseNumber},{" "}
                  {jobDetails?.address?.landmark}, {jobDetails?.address?.street}
                  , {jobDetails?.address?.city}, {jobDetails?.address?.province}
                </div>
              </div>
            </Grid>
          </Grid>
          {jobDetails?.description && 
          <Grid item xs={12} sx={{ p: 2 }} className="main-box-viewJobs-card">
            <div className="labelDivCls">
              <div className="labelCls"> Job Details</div>
              <div className="valueCls">{jobDetails?.description}</div>
            </div>
            <Grid></Grid>
          </Grid> }
          <DialogComp
            openPopup={openPopup}
            closeFunc={closeFunc}
            popuptitle={popuptitle}
            popupContent={popupContent}
            buttonValue={buttonArr}
          />
        </Grid>
      </Grid>
        <Grid
          display="flex"
          justifyContent="center"
          bgcolor="#ffffff"
          padding="12px 20px 12px 20px"
          width="100%"
          style={{ position: "sticky", bottom: "0" }}
        >
          {/* <Button
            name={appState.jdButton1}
            color="green"
            onClick={() => btnEvntHandlerMap.get(appState.jdButton1)(appState.jdButton1)}
          /> */}

          <Button
          name="Respond to Job Request"
          color="green"
          onClick={() => respondFunc()}
        />
        </Grid>
    </Grid>
  );
}
