import { React, useState, useEffect } from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@mui/material";
import Card from "../../components/JobCard";
import JobDetailComp from "../../components/JobDetailComp";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useUpdateJobStatusMutation } from "../../reducer/viewJobReducer";
import { useRevisedSendQuoteDetailsMutation } from "../../reducer/quoteReducer";
import DialogComp from "../../components/Dialog-Popup";
import moment from "moment";
import "./styles.css";
import {
  getAppState,
  getNextJobState,
  getNextQuoteState,
  QuoteBTNs,
  JobBTNs,
  JobState,
} from "../../businesslogic";

export default function JobDetailsFun(props) {
  const navigate = useNavigate();
  const { stateValue } = props;
  //let quoteStatus = stateValue?.quoteStatus;
  const [showInspectedJob, setShowInspectedJob] = useState(false);
  const [showStartedJob, setSHowStartedJob] = useState(false);
  const [btmSht, setbtmSht] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);
  const [jobDetailsData, setJobDetailsData] = useState(null);
  let quoteStatus = null,
    jobStatus = null,
    appState = null;
  var btnEvntHandlerMap;

  quoteStatus = stateValue?.quotes?.[0]?.status;
  jobStatus = stateValue?.status;

  console.log(jobStatus, quoteStatus);
  appState = getAppState(jobStatus, quoteStatus);
  console.log("App State", appState);

  btnEvntHandlerMap = new Map();
  btnEvntHandlerMap.set(JobBTNs.Accept, acceptJob_EH);
  btnEvntHandlerMap.set(JobBTNs.Reject, rejectJob_EH);
  btnEvntHandlerMap.set(JobBTNs.Inspect, inspectJob_EH);
  btnEvntHandlerMap.set(JobBTNs.Start, startJob_EH);
  btnEvntHandlerMap.set(JobBTNs.Complete, completeJob_EH);

  useEffect(() => {
    setJobDetailsData(stateValue);
  }, [stateValue]);

  function acceptJob_EH(buttonName) {
    appState.action = buttonName;
    callApi(appState);
  }

  function rejectJob_EH(buttonName) {
    appState.action = buttonName;
    rejectJobFun();
  }

  function inspectJob_EH(buttonName) {
    appState.action = buttonName;
    JobInspectPopupFunc();
    console.log(showInspectedJob, showStartedJob);
  }

  function startJob_EH(buttonName) {
    appState.action = buttonName;
    callApi(appState);
  }

  function completeJob_EH(buttonName) {
    appState.action = buttonName;
    JobCompletePopupFunc();
  }

  function callApi(appState) {
    const nextQuoteState = getNextQuoteState(appState);
    console.log("nextQuoteState", nextQuoteState);
    const nextJobState = getNextJobState(appState);
    console.log("nextJobState", nextJobState);

    if (nextJobState != null) {
      sendJobStatusAPI(nextJobState);
    }
    if (nextQuoteState != null) {
      sendQuoteStatusAPI(nextQuoteState);
    }
  }

  let firstName = jobDetailsData?.address?.firstname;
  let JobId = jobDetailsData?.id;

  function startedJobFun(updateJobStatus) {
    const timer = setTimeout(() => {
      // clickJobStartedFn(updateJobStatus);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function rejectJobFun() {
    setOpenPopup(true);
    SetPopupTitle("Are you sure?");
    SetPopupContent(
      "You are trying to reject the Job request from " +
        jobDetailsData?.address?.firstname +
        ", Please confirm if you are sure."
    );
    let btnValues = [
      {
        id: "1",
        value: "Confirm",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: () => callApi(appState),
      },
      {
        id: "1",
        value: "Cancel",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: closeFunc,
      },
    ];
    setButtonArr(btnValues);
  }

  function DateConverter(data) {
    const date = moment(data).format("L");
    return date;
  }

  function TimeConverter(data) {
    const date = moment(data).format("LT");
    return date;
  }

  function acceptJobFun() {
    setOpenPopup(true);
    SetPopupTitle("Thank You");
    let data =
      "Thank you for accepting the requested service on " +
      DateConverter(jobDetailsData?.date) +
      " at " +
      TimeConverter(jobDetailsData?.time) +
      " to the given address";
    SetPopupContent(data);
    let btnValues = [
      {
        id: "1",
        value: "Close",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: redirectHomePage,
      },
    ];
    setButtonArr(btnValues);
  }

  function inspectJobFun() {
    setOpenPopup(true);
    SetPopupTitle("Thank You!");
    SetPopupContent("You have marked the Inspection at site successfully.");
    let btnValues = [
      {
        id: "1",
        value: "Ok",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: closeInspectPopup,
      },
    ];
    setButtonArr(btnValues);
  }

  function closeInspectPopup() {
    setOpenPopup(false);
    setSHowStartedJob(true);
  }

  function confirmRejectFunc() {
    setOpenPopup(false);
    SetPopupTitle("Confirm Rejection");
    SetPopupContent("You have rejected the selected Job request from Anna.");
    let btnValues = [
      {
        id: "1",
        value: "Ok",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: redirectHomePage,
      },
    ];
    setButtonArr(btnValues);
    setOpenPopup(true);
  }

  function closeFunc() {
    setOpenPopup(false);
  }

  function redirectHomePage() {
    setOpenPopup(false);
    navigate("/plumber");
  }

  function clickJobStartedFn(updtJobStatus) {
    setSHowStartedJob(false);
    appState = getAppState(updtJobStatus, quoteStatus);
    console.log(appState);
  }

  function redirectQuotesTab() {
    setOpenPopup(false);
    let stateObj = {
      tabVal: 1,
      jobStatus: jobStatus,
      quoteStatus: quoteStatus,
      id: stateValue.id,
      editQuote: true,
    };
    window.location.reload();
    navigate("/Job-quoteCal", { state: stateObj });
  }

  function JobInspectPopupFunc() {
    setOpenPopup(true);
    SetPopupTitle("Are you sure?");
    SetPopupContent(
      "Please continue if you have inspect the Job or Edit the Quote if you have anything to change in the existing calculation."
    );
    let btnValues = [
      {
        id: "1",
        value: "Edit",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: redirectQuotesTab,
      },
      {
        id: "1",
        value: "Continue",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: () => callApi(appState),
      },
    ];
    setButtonArr(btnValues);
  }

  function JobCompletePopupFunc() {
    setOpenPopup(true);
    SetPopupTitle("Are you sure?");
    SetPopupContent(
      "Please continue if you have complete the Job or Edit the Quote if you have anything to change in the existing calculation."
    );
    let btnValues = [
      {
        id: "1",
        value: "Edit",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: redirectQuotesTab,
      },
      {
        id: "1",
        value: "Continue",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: () => callApi(appState),
      },
    ];
    setButtonArr(btnValues);
  }

  function jobCompletedPopup() {
    setOpenPopup(true);
    SetPopupTitle("Thank You!");
    SetPopupContent(
      "Thank you for completing Job #1220. Please ensure job completion by uploading all the invoices and bills."
    );
    let btnValues = [
      {
        id: "1",
        value: "Continue",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: uploadInvoicesFun,
      },
    ];
    setButtonArr(btnValues);
  }

  function uploadInvoicesFun() {
    setOpenPopup(false);
    navigate("/UploadInvoice");
  }

  const [sendJobStatus, { data: responsedata2 }] = useUpdateJobStatusMutation();
  async function sendJobStatusAPI(str) {
    const paramsData = {
      status: str, //  job status
    };
    try {
      let Id = JobId;
      const response = await sendJobStatus({ Id, paramsData });
      if (response.data) {
        let updtJobStatus = response.data.status;
        if (str === "JobAccepted") {
          acceptJobFun();
        } else if (str === "New") {
          confirmRejectFunc();
        } else if (str === "JobFinalised") {
          setOpenPopup(false);
          setShowInspectedJob(true);
        } else if (str === "JobStarted") {
          setSHowStartedJob(true);
          startedJobFun(updtJobStatus);

          console.log(appState);
        } else if (str === "JobCompleted") {
          jobCompletedPopup();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [revisedSendQuoteAPI, { data: revisedValues }] =
    useRevisedSendQuoteDetailsMutation();
  async function sendQuoteStatusAPI(str) {
    const paramsData = {
      status: str, //  Quote status
    };
    let method = "PATCH";
    let Id = Number(JobId);
    try {
      const response = await revisedSendQuoteAPI({ Id, method, paramsData });
      if (response.data) {
        // revisedQuoteProceedFun();
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getButtonsFunc() {
    return (
      <>
        <Grid
          display="flex"
          justifyContent={
            appState?.jdButton1 && appState?.jdButton2
              ? "space-between"
              : "center"
          }
          bgcolor="#ffffff"
          padding="12px 20px 12px 20px"
          width="100%"
          style={{ position: "sticky", bottom: "0" }}
        >
          {appState?.jdButton1 && (
            <Button
              color="white"
              name={appState?.jdButton1}
              onClick={() =>
                btnEvntHandlerMap.get(appState.jdButton1)(appState.jdButton1)
              }
            />
          )}
          {appState?.jdButton2 && (
            <Button
              color="green"
              name={appState?.jdButton2}
              onClick={() =>
                btnEvntHandlerMap.get(appState.jdButton2)(appState.jdButton2)
              }
            />
          )}
        </Grid>
      </>
    );
  }

  return (
    <Grid>
      <Grid container padding="4%">
        <>
          {quoteStatus === "JobAccepted" ? (
            <>
              <Grid
                borderRadius="5px"
                bgcolor="#FFEFD9"
                width="100%"
                p={2}
                mb={2}
              >
                <Typography>Hello {firstName},</Typography>
                <Typography>has accepted your quotation.</Typography>
              </Grid>
            </>
          ) : null}
          <Card profile={true} data={jobDetailsData} showTitle={false} />
        </>
        {/* );
        })} */}
      </Grid>

      <JobDetailComp JobDetailsData={jobDetailsData}></JobDetailComp>
      <Grid container sx={{ p: "4%", mt: "-30px" }}>
        <Grid item xs={12} sx={{ p: 2 }} className="main-box-viewJobs-card">
          <Grid>
            <div className="labelDivCls">
              <div className="labelCls">Property Type</div>
              <div className="valueCls">{jobDetailsData?.propertyType}</div>
            </div>
            <div className="labelDivCls">
              <div className="labelCls">Location/Address</div>
              <div className="valueCls">
                {jobDetailsData?.address?.houseNumber},{" "}
                {jobDetailsData?.address?.landmark},{" "}
                {jobDetailsData?.address?.street},{" "}
                {jobDetailsData?.address?.city},{" "}
                {jobDetailsData?.address?.province}
              </div>
            </div>
          </Grid>
        </Grid>
        {jobDetailsData?.description && 
        <Grid item xs={12} sx={{ p: 2 }} style={{border:'5px solid red'}} className="main-box-viewJobs-card">
        <div className="labelDivCls">
          <div className="labelCls"> Job Details</div>
          <div className="valueCls">{jobDetailsData?.description}</div>
        </div>

          <Grid></Grid>
        </Grid> }
      </Grid>
      <BottomSheet open={btmSht} onDismiss={() => setbtmSht(false)}>
        <Grid m={2}>
          <CloseIcon
            onClick={() => setbtmSht(false)}
            style={{
              position: "absolute",
              top: "5%",
              right: "3%",
              fontSize: 29,
              color: "#0D5C75",
            }}
          />
          <Typography
            style={{
              color: "#0D5C75",
              marginBottom: "5%",
            }}
          >
            Payment Details
          </Typography>
          <Typography>Total amount paid : R 75.00</Typography>
          <Typography
            style={{
              color: "#0D5C75",
              marginBottom: "5%",
              fontSize: "12px",
            }}
          >
            On 18/10/2022
          </Typography>
          <Grid borderTop="1px solid #DBDBDB" pt={3} mb={3} container>
            <Grid xs={5}>Payment Mode </Grid>
            <Grid> : Payfast</Grid>
          </Grid>
        </Grid>
      </BottomSheet>

      <DialogComp
        openPopup={openPopup}
        closeFunc={closeFunc}
        popuptitle={popuptitle}
        popupContent={popupContent}
        buttonValue={buttonArr}
      />

      {showInspectedJob || showStartedJob ? (
        <>
          <Grid
            display="flex"
            justifyContent="center"
            bgcolor="#ffffff"
            padding="12px 20px 12px 20px"
            width="100%"
            boxShadow="-42px -24px 42px #C4C4C464"
            style={{ position: "sticky", bottom: "0" }}
          >
            {showInspectedJob ? (
              <Button name="Job Inspected" color="borderLess" />
            ) : null}
            {showStartedJob ? (
              <Button name="Job has Started" color="borderLess" />
            ) : null}
          </Grid>
        </>
      ) : (
        getButtonsFunc()
      )}
    </Grid>
  );
}
