import React, { useEffect, useState } from "react";
import { Geolocation } from "@capacitor/geolocation";
import Map from "./Map";

const CurrentLoc = () => {
  const [geolat, setGeolat] = useState(0);
  const [geolog, setGeolog] = useState(0);
  // const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    printCurrentPosition();
  }, [geolat, geolog]);

  const printCurrentPosition = async () => {
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   console.log(
    //     "###user current location - ",
    //     position.coords.latitude,
    //     position.coords.longitude
    //   );
    //   setGeolat(position.coords.latitude);
    //   setGeolog(position.coords.longitude);
    // });

    const coordinates = await Geolocation.getCurrentPosition();
    // console.log("###user current location - ", coordinates.coords);
    setGeolat(coordinates.coords.latitude);
    setGeolog(coordinates.coords.longitude);
  };

  return (
    <>
      {/* <h2>
        Current Lat, Lng :: {geolat} , {geolog}{" "}
      </h2> */}
      {console.log("###user current Lat, Lng :: ", geolat, geolog)}
      <Map geolat={geolat} geolog={geolog} />
    </>
  );
};
export default CurrentLoc;
