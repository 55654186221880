import React from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./styles.css";

export default function HomeHeader(props) {
    const HeaderTitle=props.header;
    const navigate = useNavigate();
    return (
        <Grid style={{ display: "flex", alignItems: "center", padding: '0px 16px 5px 10px', position: "sticky", top: "0" }} mt={2} p={2}>
            <HomeOutlinedIcon
                onClick={() => {
                    navigate('/Plumber');
                }}
                style={{
                    fontSize: "24px",
                    color: "#0d4f57",
                }}
            />
            <ArrowBackIcon
                onClick={() => {
                    navigate(-1);
                }}
                style={{ fontSize: "24px", color: "#0d4f57", paddingLeft: "4%" }} />
            <Typography
                pl={2}
                style={{
                    fontSize: "15px", fontWeight: 'normal', letterSpacing: '1', color: '#535353', width:'175px'
                }}
            >
                {HeaderTitle}
            </Typography>
        </Grid>
    )
}