import * as React from "react";
import "./styles.css";
import { Box, Grid, Checkbox, InputAdornment, Typography } from "@mui/material";
import Select from "react-select";
import Button from "../../components/Button";
import TextFieldApp from "../../components/TextField";

export default function NewAddressScrenFun(props) {
  const { closeAddressFunc, formValues, setFormValues, tempArr, setTempArr } =
    props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  var name = [
    {
      id: "1",
      label: "Customer's full name*",
      plcHol: "Name",
      name: "name",
      error: formValues?.name === "",
      helperText: formValues?.name === "" ? "Please enter full name" : "",
    },
  ];
  const phoneRegex = /^\d{10}$/.test(formValues?.contact);

  const disableSaveButton = React.useMemo(() => {
    if (
      formValues?.name === "" ||
      formValues?.contact === "" ||
      formValues?.street === "" ||
      formValues?.houseNo === "" ||
      formValues?.city === "" ||
      formValues?.province === "" ||
      !phoneRegex
    ) {
      return true;
    } else return false;
  }, [formValues, phoneRegex]);

  var contact = [
    {
      id: "2",
      label: "Contact Number*",
      plcHol: "Add contact number",
      name: "contact",
      type: "number",
      error: formValues?.contact === "" ? true : !phoneRegex ? true : false,
      helperText:
        formValues?.contact === ""
          ? "Please enter contact number"
          : !phoneRegex
          ? "Please enter valid contact number"
          : "",
    },
  ];

  var listTXFL = [
    {
      id: "1",
      label: "House / Apartment Number*",
      plcHol: "Add house Number",
      name: "houseNo",
      error: formValues?.houseNo === "",
      helperText:
        formValues?.houseNo === "" ? "Please enter Apartment Number" : "",
    },
    {
      id: "2",
      label: "Landmark",
      plcHol: "Housename",
      name: "landmark",
    },
    {
      id: "3",
      label: "Street / Area Name*",
      plcHol: "Housename",
      name: "street",
      error: formValues?.street === "",
      helperText:
        formValues?.street === "" ? "Please enter street / area name" : "",
    },
  ];
  const cityList = [
    { value: "Delhi", label: "Delhi" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Bangalore", label: "Bangalore" },
  ];

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#F2F2F2",
        primary: "#72727233",
      },
    };
  }

  const customStyles1 = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "none",
      color: state.isSelected ? "#ffffff" : "black",
      // padding: "3px",
      // fontSize: "13px",
      backgroundColor: state.isSelected ? "#0d4f57" : "white",
    }),
    control: () => ({
      display: "flex",
      border: "1.5px solid",
      borderColor: "#3c8c9e",
      backgroundColor: "none",
      borderRadius: "3px",
      padding: "4px 0px",
      // minHeight: "31px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      // fontSize: "13px",
      color: "#000000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.25)",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0D5C75",
      // backgroundColor: "red",
      // padding:'10px'
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
  };
  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "none",
      color: state.isSelected ? "#ffffff" : "black",
      // padding: "3px",
      // fontSize: "13px",
      backgroundColor: state.isSelected ? "#0d4f57" : "white",
    }),
    control: () => ({
      display: "flex",
      border: "1.5px solid",
      borderColor: "#3c8c9e",
      backgroundColor: "none",
      borderRadius: "3px",
      padding: "4px 0px",
      // minHeight: "31px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      // fontSize: "13px",
      color: "#000000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.25)",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0D5C75",
      // backgroundColor: "red",
      // padding:'10px'
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
  };
  function closePopup() {
    const id = tempArr.length;
    setTempArr((pre) => {
      return [
        ...pre.slice(0, 0),
        {
          addressId: id + 1,
          city: formValues.city.value,
          province: formValues.province.value,
          landmark: formValues.landmark,
          street: formValues.street,
          houseNo: formValues.houseNo,
          name: formValues.name,
          contact: formValues.contact,
        },
        ...pre.slice(0, tempArr.length),
      ];
    });
    setFormValues({ ...formValues, addressId: id + 1 });
    closeAddressFunc();
  }
  return (
    <div className="container_Address">
      {/* <Grid container> */}
      <Box component="form" noValidate>
        <Box
          padding="8%"
          sx={{
            borderRadius: "8px 8px",
            bgcolor: "#F2F2F2",
          }}
        >
          <div className="addressTitleCls1">
            Add new address(An alternate contact for plumber)
          </div>
          <div className="addressTitleCls2">
            This contact will be used by the plumber to communicate further
          </div>
        </Box>

        <Grid>
          <Grid padding="7%">
            <TextFieldApp
              listTXFL={name}
              handleChange={handleChange}
              formValues={formValues}
              className=""
            />

            <TextFieldApp
              listTXFL={contact}
              // handleChange={handleChange}
              handleChange={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
                setFormValues({ ...formValues, contact: e.target.value });
              }}
              formValues={formValues}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      style={{
                        borderRight: "1.5px solid #3C8C9E",
                        paddingRight: "10px",
                      }}
                    >
                      +270
                    </Typography>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 10, // does not work if (type=number) is defined
              }}
            />
          </Grid>

          <Grid item className="addressHead"></Grid>
          <Grid padding="7%" container>
            <Grid size="small" width="100%">
              <Grid item className="">
                <div className="addressCls">Address</div>
              </Grid>
              <Grid
                width="100%"
                sx={{
                  "& .MuiFormHelperText-root": { marginLeft: 0 },
                }}
              >
                <TextFieldApp
                  listTXFL={listTXFL}
                  handleChange={handleChange}
                  formValues={formValues}
                />
              </Grid>
              <br />
              <label
                className={
                  formValues?.city === "" ? "redlableColor" : "lableCls"
                }
              >
                Select City*
              </label>
              <div
                style={{
                  paddingTop: "5px",
                  paddingBottom: "20px",
                }}
              >
                <Select
                  placeholder="Select city"
                  // value={formValues?.city}
                  options={cityList}
                  theme={customTheme}
                  styles={customStyles1}
                  isSearchable={false}
                  defaultValue={formValues?.city}
                  onChange={(val) =>
                    setFormValues({ ...formValues, city: val.value })
                  }
                />
              </div>

              <label
                className={
                  formValues?.province === "" ? "redlableColor" : "lableCls"
                }
              >
                Select Province*
              </label>
              <div
                style={{
                  paddingTop: "5px",
                  paddingBottom: "20px",
                }}
              >
                <Select
                  placeholder="Select Province"
                  // value={formValues?.province}
                  options={cityList}
                  theme={customTheme}
                  styles={customStyles2}
                  isSearchable={false}
                  defaultValue={formValues?.province}
                  onChange={(val) =>
                    setFormValues({ ...formValues, province: val.value })
                  }
                />
              </div>

              <Grid
                item
                display="flex"
                alignItems="center"
                sm={6}
                pt={1}
                className="grid"
              >
                <Checkbox
                  sx={{ paddingLeft: "2px" }}
                  //   checked={checked}
                  //   onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="checkboxTextCls">
                  Make this my default address
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid bgcolor="#F2F2F2" item sx={{ p: 3, textAlign: "center" }}>
            <Button
              disabled={disableSaveButton}
              color="green"
              name="Save Details"
              onClick={closePopup}
            ></Button>
          </Grid>
        </Grid>
      </Box>
      {/* </Grid> */}
    </div>
  );
}
