import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { FromCamera, FromGallery } from "../../components/camera";
import "./styles.css";
import { Device } from "@capacitor/device";

export default function SplashScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    logDeviceInfo();
  }, []);

  const logDeviceInfo = async () => {
    const info = await Device.getInfo();
    console.log("DEVICE INFO ", info);
    console.log("PLATFORM ", info.platform);
    localStorage.setItem("Platform", info.platform);
  };

  return (
    <div className="container_Splash">
      <Button
        name="Date Time"
        onClick={() => {
          navigate("/DateTime");
        }}
      />
      <br />
      <Button
        name="Job Page"
        onClick={() => {
          navigate("/Job");
        }}
      />
      <br />
      <Button name="Camera" onClick={FromCamera} />
      <br />
      <Button name="Gallery" onClick={FromGallery} />
      <br />
      <Button
        name="Plumber details"
        onClick={() => {
          navigate("/plumber");
        }}
      />{" "}
      <br />
      <Button
        name="Map"
        onClick={() => {
          navigate("/map");
        }}
      />
      <br />
      <Button
        name="All Matching Jobs"
        onClick={() => {
          navigate("/allMatchingJobs");
        }}
      />
    </div>
  );
}
