import React, { useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import StarRating from "../../components/Feedback-Star";
import Button from "../../components/Button";
import DialogComp from "../../components/Dialog-Popup";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./styles.css";

export default function Feedback() {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);
  const CHARACTER_LIMIT = 250;

  const [values, setValues] = useState({
    name: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  function submitFeedbackBtn() {
    setOpenPopup(true);
    SetPopupTitle("Thank you !");
    SetPopupContent("Thank you for your valuable feedback.");
    let btnValues = [
      {
        id: "1",
        value: "BACK HOME",
        color: "borderLess",
        functionCall: redirectHomePage,
      },
    ];
    setButtonArr(btnValues);
  }

  function redirectHomePage() {
    setOpenPopup(false);
    navigate("/Plumber");
  }

  return (
    <div>
      <Grid style={{ display:"flex", alignItems:"center",padding:'16px', position: "sticky", top: "0",backgroundColor:'#FFFFFF'}} mt={2} p={2}>
          <HomeOutlinedIcon
          onClick={() => {
            navigate('/Plumber');
          }}
            style={{
              fontSize: "31",
              color: "#0d4f57",
            }}
          />
          <ArrowBackIcon
            onClick={() => {
              navigate(-1);
            }}
            style={{fontSize: "31",color: "#0d4f57",paddingLeft: "4%" }}  />
          <Typography
            pl={2}
            style={{
              fontSize: "17px",fontWeight:'600', letterSpacing:'1',color:'#535353',paddingLeft:'15px'
            }}
          >
            Feedback
          </Typography>
        </Grid>
      <Grid
        style={{ padding: "2px", backgroundColor: "#F2F2F2", lineHeight: "1" }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "0px 0px 30px 30px",
            border:'1px solid white'
          }}
        >
          <img className="card-img" src="https://picsum.photos/100" alt="" />
          <Typography style={{ fontSize: "18px", fontWeight: "700" }}>
            Anna
          </Typography>
          <Typography style={{ fontSize: "14px", fontWeight: "700" }}>
            Location Name
          </Typography>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              style={{ display: "flex", alignItems: "center", padding: "12px" }}
            >
              <Typography style={{ fontSize: "32px", fontWeight: "500" }}>
                4.2
              </Typography>
              <Grid style={{ padding: "18px", lineHeight: "1" }}>
                <StarRating size={20} value={4.5} edit={false} />
                <Typography style={{ fontSize: "12px" }}>
                  From 12 People
                </Typography>
              </Grid>
            </Grid>
            <Typography
              style={{
                paddingLeft: "18px",
                paddingRight: "8px",
                borderLeft: "1.5px solid #0D5C75",
              }}
            >
              Reviews: <span class="ReviewNum">17</span>
            </Typography>
          </Grid>
        </Grid>
        <Typography
          style={{
            marginTop: "10px",
            padding: "5% 5% 5% 5%",
            fontSize: "16px",
            fontWeight: "700",
            width: "90%",
            textAlign: "center",
            backgroundColor: "#F2F2F2",
          }}
        >
          Help us improve our services by provide your feedback on Home owner.
        </Typography>
        <Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
              lineHeight: "1",
              backgroundColor: "white",
            }}
          >
            <Typography
              style={{ fontSize: "18px", color: "#0D5C75", fontWeight: "600",paddingBottom:'5px' }}
            >
              Add Overall Rating
            </Typography>
            <StarRating value={3} size={40} />
          </Grid>
          <Grid
            style={{
              color: "#0D5C75",
              backgroundColor: "#F2F2F2",
              b0rder: "1px solid red",
            }}
          >
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 40px 12px 30px",
                borderTop: "2px solid #F2F2F2",
                marginTop: "35px",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px",color:'#0D5C75' }}>
                Wait time
              </Typography>
              <StarRating size={30} count={4} value={1} />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 40px 12px 30px",
                borderTop: "2px solid #F2F2F2",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px" }}>
                Professionalism
              </Typography>
              <StarRating size={30} count={4} value={2} />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 40px 12px 30px",
                borderTop: "2px solid #F2F2F2",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px" }}>
                Comfortness
              </Typography>
              <StarRating size={30} count={4} value={3} />
            </Grid>
            <Grid style={{ padding: "22px 15px 22px 20px" }}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: "17px",
                  fontWeight: "800",
                }}
              >
                Write your comment
              </Typography>
              <TextField
                multiline
                sx={{
                  width: "100%",
                  border: '2px solid red',
                }}
                style={{border:'1px solid #F2F2F2'}}
                placeholder="type here..."
                rows={5}
                fullWidth
                values={values.name}
                onChange={handleChange("name")}
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                  style: {
                    width: "100%",
                    backgroundColor: "white",
                    padding: "8px",
                    border: "1px solid #0D5C75",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "17px 0px 17px 0px",
            position: "sticky",
            bottom: "0",
            backgroundColor: "white",
          }}
        >
          <Button
            name="Submit Feedback"
            color="green"
            onClick={() => submitFeedbackBtn()}
          />
        </Grid>
        <DialogComp
          openPopup={openPopup}
          popuptitle={popuptitle}
          popupContent={popupContent}
          buttonValue={buttonArr}
        />
      </Grid>
    </div>
  );
}
