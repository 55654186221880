import baseAPI from "../app/baseAPI";

export const QuoteReducer = baseAPI.injectEndpoints({
  endpoints(builder) {
    return {
      sendQuoteDetails: builder.mutation({
        query(params) {
          return {
            url: `http://localhost:3000/quotes`,
            method: params.method,
            token: true,
            body: {
              ...params.paramsData,
            },
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
      revisedSendQuoteDetails: builder.mutation({
        query(params) {
          return {
            url: `http://localhost:3000/quotes/`+params.Id,
            method: params.method,
            token: true,
            body: {
              ...params.paramsData,
            },
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
    };
  },
});
export const {useSendQuoteDetailsMutation, useRevisedSendQuoteDetailsMutation} = QuoteReducer;
