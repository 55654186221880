import { React, useState, useEffect } from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@mui/material";
import HeaderFooterHoc from "../../HOC/HeaderFooterHOC";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import JobDetailsTabFun from "./JobDetailsTab";
import QuoteCalculationPage from "./QuoteCalculationTab";
import "./styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import HomeHeader from "../../components/HomeHeader";
import { useFetchViewJobDetailsbyIdQuery } from "../../reducer/viewJobReducer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ViewJobDetailsFn() {
  const [value, setValue] = useState(0);
  const Location = useLocation();
  const locState = Location.state;
  const [jobDetailsData,setJobDetailsData] = useState(null);

  const { data: jobDetails } = useFetchViewJobDetailsbyIdQuery(locState.id, {
    skip: !locState.id,
  });
  useEffect(()=>{
    setJobDetailsData(jobDetails)
  },[jobDetails])

  const navigate = useNavigate();

  useEffect(() => {
    if (locState) {
      let tabValue = locState.tabVal;
        setValue(tabValue );
      }

    
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      sx={{
        backgroundColor: "#FFF",
      }}
    >
      <Grid padding="5% 5% 2% 5%" display="flex" alignItems="center" style={{backgroundColor:'#fff'}}>
        {/* <ArrowBackIcon
          color="primary"
          style={{
            fontSize: "20",
            marginRight: "11px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="">
          <Typography ml={1} color="">
            Job no #1203
          </Typography>
        </div> */}
        <HomeHeader header={<>Job no #{locState.id}</>} bgColor="#fffffff"/>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "0% 6%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab label="Job Details" {...a11yProps(0)} />
            <Tab label="Quote Calculation" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <JobDetailsTabFun stateValue={jobDetailsData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <QuoteCalculationPage stateValue={locState} existingQuotesValue={jobDetailsData?.quotes}  paymentDetails = {jobDetailsData?.paymenthstry}/>
        </TabPanel>
      </Box>
    </Grid>
  );
}
export default ViewJobDetailsFn;

// export default HeaderFooterHoc(ViewJobDetailsFn, {
//   // showFooter: true,
//   // showHide: true,
//   bodyClassname: "",
// });
