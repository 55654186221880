import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Card from "../../components/JobCard";
import { useNavigate, useLocation } from "react-router-dom";
import CircularLoader from "../../components/loader";
import { useFetchViewJobDetailsQuery } from "../../reducer/viewJobReducer";
import HomeHeader from "../../components/HomeHeader";

function AllMatchingJobs() {
  const navigate = useNavigate();
  const location = useLocation();
  const [allJobList, setAllJobList] = useState();
  const headerTitle = location?.state;
  // if(headerTitle === 'New') {
  //   jobStatus = {
  //     firstStatus: "NEW",
  //     secondStatus: "JobAssigned"
  //   }
  // }else if(headerTitle === 'In_Progress') {
  //   jobStatus = {
  //     firstStatus: "JobAccepted",
  //   }

  // }
  
  const {
    data: jobList,
    isSuccess,
    isError,
    isLoading,
    error,
  } = useFetchViewJobDetailsQuery(headerTitle);

  useEffect(() => {
    if (jobList) {
      setAllJobList(jobList);
    }
  }, [jobList]);
  //console.log("View all matching Jobs",allJobList);

  return (
    <>
    {isLoading && (<CircularLoader position="loaderMiddle"></CircularLoader>)}
    <Grid margin="5%" bgcolor="#FFFFFF">
      <Grid
        pb={1}
        // borderBottom="0.5px solid #0D4F57"
        display="flex"
        alignItems="center"
      >
        <HomeHeader
          header={headerTitle ? <>{headerTitle} </> : <> All matching jobs</>}
        />
      </Grid>

      <Grid>
        {allJobList?.map((obj, i) => {
          return (
            <Card
              headerTitle={headerTitle}
              showTitle={true}
              profile={false}
              key={i}
              data={obj}
            />
          );
        })}
      </Grid>
    </Grid>
    </>
  );
}
export default AllMatchingJobs;

