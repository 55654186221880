import React from "react";
import { Grid, Typography } from "@material-ui/core";
import StarRating from "../../components/Feedback-Star";
import person1 from "../../assets/person1.png";
import person2 from "../../assets/person2.png";
import person3 from "../../assets/person3.png";
import person4 from "../../assets/person4.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate} from "react-router-dom";
import "./styles.css";

export default function Ratings() {
  const navigate = useNavigate();

  var ratings = [
    {
      id: "1",
      name: "John",
      profilePic: person1,
      "commnet": "Working with is a solution which connects plumber and homeowner in shortest time."

    },
    {
      id: "2",
      name: "Mark",
      profilePic: person2,
      commnet: "Working with is a solution which connects plumber and homeowner in shortest time."

    },
    {
      id: "3",
      name: "Dan",
      profilePic: person3,
      commnet: "Working with is a solution which connects plumber and homeowner in shortest time."

    },
    {
      id: "4",
      name: "Alan",
      profilePic: person4,
      commnet: "Working with is a solution which connects plumber and homeowner in shortest time."

    }

  ]


  return (
    <div>
      <Grid style={{ display: "flex", alignItems: "center", padding: '16px', position: "sticky", top: "0", backgroundColor: '#F4F4F4', zIndex:2 }} mt={2} p={2}>
        <ArrowBackIcon
          onClick={() => {
            navigate("/Plumber");
          }}
          style={{ fontSize: "31", color: "#0d4f57", paddingLeft: "4%" }} />
        <Typography
          pl={2}
          style={{
            fontSize: "1rem", fontWeight: '600', letterSpacing: '1', color: '#535353', paddingLeft: '15px'
          }}
        >
          My Rating
        </Typography>
      </Grid>
      <Grid  style={{ backgroundColor: "#ffffff", padding:"20px" }}>
      <Grid
        style={{ padding: "20px", backgroundColor: "#EAEAEA", lineHeight: "1" }}
      >
        <Typography style={{
          fontSize: "1rem",
          fontWeight: "600",
          letterSpacing: "0.09px",
          color: "#1F1F1F",
          paddingBottom: "13px",
        }}>Rating and Reviews</Typography>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            backgroundColor: "white",
            borderRadius: "10px",
            border: '1px solid white'
          }}
        >
          <Typography
            style={{ fontSize: "18px", color: "#0D5C75", fontWeight: "600", paddingTop: '25px' }}
          >
           Overall Rating
          </Typography>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              style={{ display: "flex", alignItems: "center", padding: "0px" }}
            >
              <Typography style={{ fontSize: "32px", fontWeight: "500" }}>
                4.2
              </Typography>
              <Grid style={{ padding: "18px", lineHeight: "1" }}>
                <StarRating size={20} value={4.5} edit={false} />
                <Typography style={{ fontSize: "12px" }}>
                  From 12 People
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Grid
            style={{
              marginTop: '-21px'
            }}
          >
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 6px 10px 6px",
                borderTop: "2px solid #F2F2F2",
                marginTop: "35px",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px", fontWeight: "700", color: '#0D5C75' }}>
                Wait time
              </Typography>
              <StarRating size={30} count={5} value={1} edit={false} />
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 6px 10px 6px",
                borderTop: "2px solid #F2F2F2",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px", fontWeight: "700" ,  color: '#0D5C75'}}>
                Professionalism
              </Typography>
              <StarRating size={30} count={5} value={2} edit={false}/>
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 6px 10px 6px",
                borderTop: "2px solid #F2F2F2",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ fontSize: "17px", fontWeight: "700",  color: '#0D5C75'}}>
                Comfortness
              </Typography>
              <StarRating size={30} count={5} value={3} edit={false}/>
            </Grid>
            <Typography style={{
              fontSize: "12px",
              fontWeight: "600",
              letterSpacing: "0.07px",
              color: "#1F1F1F",
              paddingTop: "20px",
              paddingBottom:"10px"
            }}>Reviewed by 3 people</Typography>

            {
              ratings.map(item => {
                return (
                  <Grid style={{ display: "flex", alignItems: "center", marginBottom: '7px', padding: '25px', backgroundColor: '#ffffff' }}>
                    <Grid>
                      <img src={item.profilePic} alt="profile" />
                      <Typography>{item.name}</Typography>
                    </Grid>

                    <Typography style={{
                      fontStyle: 'italic',
                      letterSpacing: "0.07px",
                      lineHeight: 'normal',
                      paddingLeft: "25px",
                    }}>{`"${item.commnet}"`}</Typography>

                  </Grid>

                )
              })
            }


          </Grid>
        </Grid>

      </Grid>
      </Grid>
     
    </div>
  );
}
