const ManageAddressdata = [
    {
      addressId: 1,
      name: "Test 1",
      contact: 9287363123,
      city: "Bangalore",
      province: "Delhi",
      landmark: "landmark1",
      street: "area1",
      houseNo: "house1",
      title: "Address 1"
    },
    {
      addressId: 2,
      name: "Test 2",
      contact: 7584930098,
      city: "Mumbai",
      province: "Delhi",
      landmark: "landmark2",
      street: "area2",
      houseNo: "house2",
      title: "Address 2"
    },
    {
      addressId: 3,
      name: "Test 3",
      contact: 9236308642,
      city: "Bangalore",
      province: "Mumbai",
      landmark: "landmark3",
      street: "area3",
      houseNo: "house3",
      title: "Address 3"
    },
  ];
  export { ManageAddressdata };
  