import CircularProgress from "@mui/material/CircularProgress";
import "./styles.css";

export default function circularLoader(props) {
  const {position, size} = props;
  return (
    <div style={{width : '75%', display: 'flex', justifyContent:'center'}}>
       <div className={`custom-position ${position}`}>
        <CircularProgress size={size} color="secondary" />
      </div>
    </div>
  )
}

