import { React, useEffect, useState,useRef } from "react";
import { Typography } from "@material-ui/core";
import DialogComp from "../../components/Dialog-Popup";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

import  Burger from '../../components/Burger';
import  Menu  from '../../components/Menu';

export default function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";


  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);

  const [nameVal, setName] = useState("");
  useEffect(() => {
    let value = localStorage.getItem("login");
    if (value) {
      setName("Hi, Name");
    }
  }, []);

  function closeFunc() {
    setOpenPopup(false);
  }

  function loginPopupfn() {
    setOpenPopup(true);
    SetPopupTitle("Login or Sign Up");
    SetPopupContent(
      "You have to Login or Sign up to access the Job request details."
    );
    let btnValues = [
      {
          id: "1",
          value: "Login/Sign Up",
          fontWeight: "bold",
          color: "green",
          showCondition: "true",
          functionCall:  loginFn
      }
  ];
    setButtonArr(btnValues);
  }

  function loginFn() {
    localStorage.setItem("login", true);
    navigate("/login")
  }

  return (
    <div className="container_header">
      <div
        style={{
          display: "flex",
          padding: "0% 5%",
          justifyContent: nameVal? "flex-start" : "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
      
         <div>
            <ThemeProvider theme={theme}>
              <div>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
          </div>
      </ThemeProvider>
      </div>
        {!nameVal ? (
          <>
            {"DigiAqua"}
            <div>
              <Typography></Typography>
            </div>
            <div            
              onClick={() => loginPopupfn()}
            >
              Login/Signup
            </div>
          </>
        ) : (
          <div>
           
            <Typography style={{marginLeft:'55px'}}>{nameVal}</Typography>
          </div>
        )}
      
      </div>
      <DialogComp
        openPopup={openPopup}
        closeFunc={closeFunc}
        popuptitle={popuptitle}
        popupContent={popupContent}
        buttonValue={buttonArr}
      />
    </div>
  );
}
