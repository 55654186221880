import React, { useState, useLayoutEffect } from "react";
import { Typography, Grid } from "@mui/material";
import Button from "../../components/Button";
import "./styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextFieldApp from "../../components/TextField";
import { useNavigate } from "react-router-dom";
import { FromCamera } from "../../components/camera";
import { Device } from "@capacitor/device";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

export default function UploadInvoice() {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    logDeviceInfo();
  }, []);

  const logDeviceInfo = async () => {
    const info = await Device.getInfo();
    // console.log("DEVICE INFO ", info);
    // console.log("PLATFORM ", info.platform);
    localStorage.setItem("Platform", info.platform);
  };
  const [count, setCount] = useState(0); // state change to refresh UI
  const [imageArray, setImageArray] = useState([]);

  function getBlobUrl() {
    let tempBlobObj = FromCamera();
    tempBlobObj
      .then((blob) => {
        imageArray.push(blob);
        setCount(count + 1); // refresh UI
        var fileFromBlob = new File([blob], "filename", {
          type: blob.type,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const listTXFL = [
    {
      plcHol: "",
      type: "number",
    },
  ];
  const onChange = (e) => {
    const files = e.target.files;
    imageArray.push(files[0].name);
    setCount(count + 1); // a state needs to be changed to refresh UI
    // console.log("###", imageArray);
    // e.target.value = "";
  };
  function refreshArr(index) {
    let remoteNamex;
    // remove from an array on the basis of index
    const updatedArr = imageArray.filter((item, j) => {
      remoteNamex = item.remoteName;
      return j != index;
    });
    setImageArray(updatedArr);
    // remove from an array on the basis of value
    // const updatedArr1 = ARRAYNAME.filter((item, j) => {
    //   return remoteNamex != item.remoteName;
    // });
    //  setImageArray(updatedArr1);
  }

  return (
    <>
      <Grid className="upload-invoice-container" m="5%">
        <Grid display="flex" alignItems="center" mt={2} pb={2}>
          <HomeOutlinedIcon
            style={{
              fontSize: "31",
              color: "#0d4f57",
            }}
            onClick={() => {
              navigate("/plumber");
            }}
          />{" "}
          <ArrowBackIcon
            style={{
              fontSize: "31",
              color: "#0d4f57",
              paddingLeft: "4%",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />{" "}
          <Typography
            pl={2}
            style={{
              fontSize: "16px",
            }}
          >
            Job no #1220
          </Typography>
        </Grid>
        <h4
          style={{
            color: "#0d4f57",
          }}
        >
          Upload Invoices*
        </h4>
        <label style={{ fontSize: "14px" }}>
          Total parts price from Invoice/Bills
        </label>
        <TextFieldApp
          listTXFL={listTXFL}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" size="small">
                <AttachMoneyIcon
                  style={{
                    borderLeft: "1.5px solid #3C8C9E",
                    paddingLeft: "10px",
                  }}
                >
                  {/* {formValues.showPassword ? <Visibility /> : <VisibilityOff />} */}
                </AttachMoneyIcon>
              </InputAdornment>
            ),
            style: { fontSize: 16 },
          }}
        />
        <Typography
          style={{
            color: "#0d4f57",
          }}
        >
          <h4>Upload all the invoices that you've purchased</h4>
        </Typography>
        <Grid p={0.5} borderRadius="3px" bgcolor="#F7F7F7">
          <Typography style={{ color: "#0d4f57", fontSize: "14px" }}>
            .PDF and jpg format
          </Typography>
        </Grid>
        {localStorage.getItem("Platform") == "web" ? (
          <>
            <Grid
              m={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <label htmlFor="uploadDoc_button">
                <div className="upload-invoices-btn">
                  <text>Upload Invoices</text>
                </div>
              </label>
              <input
                id="uploadDoc_button"
                // disabled={disabled}
                accept="image/*, application/pdf"
                type="file"
                name="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid
              m={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                Upload Invoices
              </Typography>
              <Grid display="flex" mt={2}>
                {" "}
                <Grid id="icon-div">
                  <PhotoCameraOutlinedIcon
                    id="camera-icon"
                    style={{
                      color: "#0D5C75",
                      fontSize: "35",
                    }}
                    onClick={getBlobUrl}
                  />
                  <Typography>Camera</Typography>
                </Grid>
                <Grid id="icon-div">
                  <label htmlFor="uploadDoc_button">
                    <div>
                      <FileUploadOutlinedIcon
                        style={{
                          color: "#0D5C75",
                          fontSize: "35",
                        }}
                      />
                    </div>
                  </label>
                  <input
                    id="uploadDoc_button"
                    // disabled={disabled}
                    accept="application/pdf"
                    type="file"
                    name="file"
                    onChange={(e) => onChange(e)}
                  />
                  <Typography>Files</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <>
          {" "}
          {imageArray.map((obj, i) => {
            return (
              <Grid
                key={i}
                display="flex"
                justifyContent="space-between"
                borderRadius="5px"
                alignItems="center"
                bgcolor="#E1F5F8"
                p={0.7}
                pl={1.5}
                mb={1}
              >
                <Typography
                  id="two-lines-text-css"
                  style={{ color: "#0d4f57", fontSize: "14px" }}
                >
                  {obj}
                </Typography>
                <CloseIcon
                  onClick={() => refreshArr(i)}
                  style={{
                    padding: "3px",
                    borderRadius: "100px",
                    background: "white",
                    color: "#0d4f57",
                    border: "0.2px solid rgb(221, 221, 221)",
                    marginLeft: "10px",
                    fontSize:'20'
                  }}
                />
              </Grid>
            );
          })}
        </>
      </Grid>
      <Grid
        position="absolute"
        bottom="0"
        pt={2}
        pb={2}
        width="100%"
        display="flex"
        justifyContent="center"
        boxShadow="-42px -24px 42px #C4C4C464"
      >
        <Button
          onClick={() => {
            navigate("/uploadImages");
          }}
          name="Next"
          color="green"
        />
      </Grid>
    </>
  );
}
