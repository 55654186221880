import { React, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import "./style.css";

export default function TotalSummaryBoxComp(props) {
  const { formValues } = props;
  const [expanLabourFee, setShowExpLabourFee] = useState(false);
  const [labourTotal, setLabourTotal] = useState();

  function showSubDivisonFld() {
    expanLabourFee ? setShowExpLabourFee(false) : setShowExpLabourFee(true);
    if (expanLabourFee) {
      calculateLabourAmt();
    }
  }

  function calculateLabourAmt() {
    if (formValues) {
      let labourCstVal =
        formValues && formValues["laborCharge"] ? formValues["laborCharge"] : 0;
      let calloutFeeVal =
        formValues && formValues["transportCharge"] ? formValues["transportCharge"] : 0;
      let tot = Number(labourCstVal) + Number(calloutFeeVal);
      setLabourTotal(tot);
    }
  }

  useEffect(()=>{
    calculateLabourAmt();
  },[formValues])

 
  return (
    <Grid container>
      <Grid
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        padding="5%"
        bgcolor="white"
        border="0.5px solid #3C8C9E"
        borderRadius="6px"
        paddingBottom=" 7%"
        sx={{ boxShadow: "0px 3px 6px #7E7E7E29" }}
      >
        <div style={{ fontSize: "18px", fontWeight: "500" }}>
          TOTAL QUOTE SUMMARY
        </div>
        <Grid container spacing={1} pt={2} pb={2}>
          <Grid item xs={8}>
            <span className="summaryLabelCls"> Labour fee</span>
            <div className="insideCntCls" onClick={() => showSubDivisonFld()}>
              Labour fee calculation details
            </div>
            {expanLabourFee ? (
              <Grid container pl={4} ml={2} pt={1}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="labourFee">Labour fee</span>
                  <span className="labourFee">
                    <b>
                      {formValues.laborCharge ? "R " + formValues.laborCharge : "-"}
                    </b>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="labourFee">Call out fee</span>
                  <span className="labourFee">
                    <b>
                      {formValues.transportCharge
                        ? "R " + formValues.transportCharge
                        : "-"}
                    </b>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="labourFee">Total</span>
                  <span className="labourFee">
                    <b>{labourTotal}</b>
                  </span>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <span className="summaryValueCls">
              {" "}
              {labourTotal ? 'R '+ labourTotal : '-'}
            </span>
          </Grid>

          <Grid item xs={8}>
            <span className="summaryLabelCls">Total hours</span>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={3}>
            <span className="summaryValueCls">
              {formValues.hours ? formValues.hours + " hrs" : "-"}
            </span>
          </Grid>

          <Grid item xs={8}>
            <span className="summaryLabelCls">DigiAqua Service charge</span>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <span className="summaryValueCls">
              {" "}
              {formValues.serviceCharge ? "R " + formValues.serviceCharge : "-"}
            </span>
          </Grid>
        </Grid>

        <Typography
          borderTop="1px dashed #3C8C9E;"
          borderBottom=".5px solid #3C8C9E;"
          fontSize="20px"
          id="jcsb"
          pb={1}
          pt={1}
        >
          <Grid container>
            <Grid item xs={8}>
              <span
                className="summaryLabelCls"
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                TOTAL
              </span>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <span
                className="summaryValueCls"
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                {formValues.amount ? "R " + formValues.amount : "-"}
              </span>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
}
