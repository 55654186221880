import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../service/response';

// initialize an empty api service that we'll inject endpoints into later as needed
const baseAPI = createApi({
    reducerPath: 'baseAPI',
    baseQuery: axiosBaseQuery({
        // baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    }),
    endpoints: () => ({}),
});

export default baseAPI;