import { React, useEffect, useState } from "react";
import "./styles.css";
import Button from "../../components/Button";
import { Grid, Typography, Checkbox } from "@mui/material";
import TextField from "../../components/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogComp from "../../components/Dialog-Popup";
import TotalSummaryBox from "../../components/TotalSummaryBox";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { BottomSheet } from "react-spring-bottom-sheet";
import PaymentDetailsPg from "./paymentDetailsBS.js";
import { useNavigate } from "react-router-dom";
import {
  getAppState,
  getNextJobState,
  getNextQuoteState,
  QuoteState,
  QuoteBTNs,
  JobBTNs,
  JobState,
} from "../../businesslogic";
import { useUpdateJobStatusMutation } from "../../reducer/viewJobReducer";
import {
  useSendQuoteDetailsMutation,
  useRevisedSendQuoteDetailsMutation,
} from "../../reducer/quoteReducer";

export default function CalculationTab(props) {
  const { stateValue, existingQuotesValue, paymentDetails } = props;

  const navigate = useNavigate();
  const [formQuotesValues, setFormQuoteValues] = useState({});
  const [formProductsArr, setFormProductsArr] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);
  const [openBtmSheet, SetOpenBtmSheet] = useState(false);
  const [show, setShow] = useState(false);
  const [editQuote, setEditQuote] = useState(false);
  const [showRevisedQuote, setShowRevisedQuote] = useState(false);
  const [showCallOutFreeField, setShowCallOutFreeField] = useState(false);
  const [partsItemArr, setPartsItemArr] = useState([]);
  const [totalPartsAmt, setTotalPartsAmt] = useState();

  let percentageOfServiceCrge = 2;
  let percentageOfTax = 7;
  let newAddItemObj = {
    count: 1,
    ItemNameFld: [
      {
        plcHol: "",
        type: "text",
        name: "itemName",
      },
    ],
    itemPriceFld: [
      {
        plcHol: "",
        type: "number",
        name: "price",
      },
    ],
  };

  let defaultPartsItmsObj = {
    itemName: "",
    price: "",
    count: 1,
  };

  let QuoteValue = existingQuotesValue?.[0] || stateValue; // There are 2 array in quotes, So are displaying only first array value
  let quoteStatus = QuoteValue?.status || stateValue?.quoteStatus;
  let jobStatus = stateValue?.jobStatus;
  console.log("Job Status , Quote Status", jobStatus, quoteStatus);

  const appState = getAppState(jobStatus, quoteStatus);
  console.log("App State", appState);

  var btnEvntHandlerMap = new Map();
  btnEvntHandlerMap.set(QuoteBTNs.Submit, submitQuote_EH);
  btnEvntHandlerMap.set(QuoteBTNs.Edit, EditQuote_EH);

  function submitQuote_EH(buttonName) {
    appState.action = buttonName;
    sendQuoteFn();
  }

  function EditQuote_EH(buttonName) {
    appState.action = buttonName;
    setShowRevisedQuote(true);
    setEditQuote(true);
  }

  useEffect(() => {
    if (Array.isArray(existingQuotesValue) && existingQuotesValue.length) {
      let existingQuiteArr = existingQuotesValue?.[0];
      setFormQuoteValues(existingQuiteArr); // There are 2 array in quotes, So are displaying only first array value
      let existingPartsArr = existingQuiteArr.parts.items;
      if (existingQuiteArr.transportCharge) {
        setShowCallOutFreeField(true);
      }
      if (existingPartsArr) {
        setPartsItemArr(existingPartsArr);
        let makeDupArr = [];
        existingPartsArr.map((pt) => {
          makeDupArr.push(newAddItemObj);
        });
        setFormProductsArr(makeDupArr);
      }
      calcTotalPartsAmt(existingPartsArr);
    } else {
      setFormProductsArr([newAddItemObj]);
      setPartsItemArr([defaultPartsItmsObj]);
    }
    if (quoteStatus === null || stateValue.editQuote === true) {
      setEditQuote(true);
    }
    if(stateValue.editQuote === true) {
      setShowRevisedQuote(true)
    }
  }, [existingQuotesValue]);

  function openBtmSheetFun() {
    SetOpenBtmSheet(true);
  }

  function closeBtmSheet() {
    SetOpenBtmSheet(false);
  }

  const calloutFeeFld = [
    {
      plcHol: "",
      type: "number",
      name: "transportCharge",
    },
  ];

  const labourCstFld = [
    {
      plcHol: "",
      type: "number",
      name: "laborCharge",
    },
  ];

  const timeHrsFld = [
    {
      plcHol: "",
      type: "number",
      name: "hours",
    },
  ];

  const handleCalloutfeechanges = () => {
    setShowCallOutFreeField(!showCallOutFreeField);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const { value } = e.target;
    setFormQuoteValues({ ...formQuotesValues, [name]: value });
  };

  function generateAddPartsItem() {
    return formProductsArr.map((item, index) => (
      <>
        <div className="quoteLabelCls" pt={3} pb={1}>
          Item name
        </div>
        <Grid
          display="flex"
          // border="1px solid red"
          width="100%"
          // justifyContent="space-between"
        >
          <Grid width="100%">
            <TextField
              listTXFL={item.ItemNameFld}
              handleChange={(e) => addItemsChangeFunc(e, index)}
              formValues={partsItemArr[index]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" size="small">
                    <Grid
                      className="cartPlusMinus"
                      display="flex"
                      padding="3px"
                      border="0.5px solid #285B72"
                      borderRadius="100px"
                      alignItems="center"
                    >
                      <RemoveIcon
                        onClick={() => {
                          item.count = partsItemArr[index].count - 1;
                          addRemoveCntFun(partsItemArr[index].count, index);
                        }}
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "5px",
                          color: "#0D5C75",
                        }}
                      />
                      <Typography>{partsItemArr[index].count}</Typography>
                      <AddIcon
                        onClick={() => {
                          item.count = item.count + 1;
                          addRemoveCntFun(item.count, index);
                        }}
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "5px",
                          color: "#0D5C75",
                        }}
                      />
                    </Grid>
                  </InputAdornment>
                ),
                style: { fontSize: 16 },
              }}
            />
          </Grid>
        </Grid>

        <div className="quoteLabelCls" pt={3} pb={1}>
          Add approximate price
        </div>
        <TextField
          listTXFL={item.itemPriceFld}
          handleChange={(e) => addItemsChangeFunc(e, index)}
          formValues={partsItemArr[index]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" size="small">
                <AttachMoneyIcon
                  style={{
                    borderLeft: "1.5px solid #3C8C9E",
                    paddingLeft: "10px",
                  }}
                ></AttachMoneyIcon>
              </InputAdornment>
            ),
            style: { fontSize: 16 },
          }}
        />
      </>
    ));
  }

  function AddOnemoreItemsFunc() {
    let copyOfFormPrdArr = [...formProductsArr];
    copyOfFormPrdArr.push(newAddItemObj);
    setFormProductsArr(copyOfFormPrdArr);
    let copyOfPartsItemArr = [...partsItemArr];
    copyOfPartsItemArr.push(defaultPartsItmsObj);
    setPartsItemArr(copyOfPartsItemArr);
  }

  const addItemsChangeFunc = (e, indx) => {
    const name = e.target.name;
    const { value } = e.target;
    let copyOfPartsItemArr = [...partsItemArr];
    let copyIndxObj = { ...copyOfPartsItemArr[indx] };
    copyIndxObj[name] = value;
    copyOfPartsItemArr[indx] = copyIndxObj;
    setPartsItemArr(copyOfPartsItemArr);
    if (name === "price") {
      calcTotalPartsAmt(copyOfPartsItemArr);
    }
  };

  const addRemoveCntFun = (cnt, indx) => {
    let copyOfPartsItemArr = [...partsItemArr];
    let copyOfFormPrdArr = [...formProductsArr];
    if (cnt === 0) {
      copyOfPartsItemArr.splice(indx, 1);
      copyOfFormPrdArr.splice(indx, 1);
    } else {
      copyOfPartsItemArr[indx].count = cnt;
      copyOfFormPrdArr[indx].count = cnt;
    }
    setFormProductsArr(copyOfFormPrdArr);
    calcTotalPartsAmt(copyOfPartsItemArr);
  };

  function calcTotalPartsAmt(partsArr) {
    const total = partsArr.reduce(
      (prevValue, currentValue) =>
        prevValue + currentValue.count * Number(currentValue.price),
      0
    );
    setTotalPartsAmt(Math.round(total));
  }

  useEffect(() => {
    calcServiceChrge();
  }, [formQuotesValues["laborCharge"], formQuotesValues["transportCharge"]]);

  function calcServiceChrge() {
    if (!existingQuotesValue) {
      let labourCstVal =
        formQuotesValues && formQuotesValues["laborCharge"]
          ? formQuotesValues["laborCharge"]
          : 0;
      let calloutFeeVal =
        formQuotesValues && formQuotesValues["transportCharge"]
          ? formQuotesValues["transportCharge"]
          : 0;
      let totalAmt = Number(labourCstVal) + Number(calloutFeeVal);
      let serviceCharge = Math.round(
        (percentageOfServiceCrge / 100) * totalAmt
      );
      let finalTotalAmt = Math.round(serviceCharge + totalAmt);
      let taxCharge = Math.round((percentageOfTax / 100) * finalTotalAmt);
      setFormQuoteValues({
        ...formQuotesValues,
        ["serviceCharge"]: serviceCharge,
        ["tax"]: taxCharge,
        ["amount"]: finalTotalAmt,
      });
    }
  }

  function sendQuoteFn() {
    setOpenPopup(true);
    SetPopupTitle("Please confirm,");
    SetPopupContent(
      "Are you confident, that your labour quote and estimate of parts are adequate for the job request?"
    );
    let btnValues = [
      {
        id: "1",
        value: "Confirm",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: () => callApi(appState, "POST"),
      },
      {
        id: "2",
        value: "Cancel",
        fontWeight: "normal",
        color: "borderLess",
        functionCall: closeFunc,
      },
    ];
    setButtonArr(btnValues);
  }

  function revisedQuoteFun() {
    setOpenPopup(true);
    SetPopupTitle("Do you wish to change the Quote?");
    SetPopupContent(
      "You have made some changes in the previous Quote. Are you sure, you want to proceed with the new changes?"
    );
    let btnValues = [
      {
        id: "1",
        value: "Proceed",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: () => callApi(appState, "PATCH", "WithChange"),
      },
      {
        id: "2",
        value: "Cancel",
        fontWeight: "normal",
        color: "borderLess",
        functionCall: closeFunc,
      },
    ];
    setButtonArr(btnValues);
  }

  function revisedQuoteProceedFun() {
    setOpenPopup(true);
    SetPopupTitle("Thank You!");
    SetPopupContent(
      "Thank you for confirming it. You will receive the response back from Home Owner soon. You can start the execution after the Home Owner approval on revised quote."
    );
    let btnValues = [
      {
        id: "1",
        value: "Ok",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: okFunc,
      },
    ];
    setButtonArr(btnValues);
  }

  function okFunc() {
    setOpenPopup(false);
    navigate("/plumber");
  }

  function editQuoteFn() {
    setOpenPopup(true);
    SetPopupTitle("Please confirm,");
    SetPopupContent("Are you sure to Edit the Quote?");
    let btnValues = [
      {
        id: "1",
        value: "Yes",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: yesFunc,
      },
      {
        id: "2",
        value: "Go back",
        fontWeight: "normal",
        color: "borderLess",
        functionCall: closeFunc,
      },
    ];
    setButtonArr(btnValues);
  }
  const [revisedSendQuoteAPI, { data: revisedValues }] =
    useRevisedSendQuoteDetailsMutation();
  const [sendQuoteAPI, { data }] = useSendQuoteDetailsMutation();

  async function callQuoteCalcAPI(methodVal, nextQuoteState) {
    let paramsData = {
      jobId: Number(QuoteValue?.jobId || QuoteValue.id),
      status: nextQuoteState || QuoteValue.status,
      title: "Sample quote 1",
      laborCharge: formQuotesValues["laborCharge"],
      transportCharge: formQuotesValues["transportCharge"],
      tax: formQuotesValues["taxCharge"],
      serviceCharge: formQuotesValues["serviceCharge"],
      amount: formQuotesValues["amount"],
      hours: formQuotesValues["hours"],
      parts: {
        items: partsItemArr,
      },
    };
    let method = methodVal;
    if (method === "POST") {
      try {
        const response = await sendQuoteAPI({ method, paramsData });
        if (response.data) {
          confirmQuoteFunc();
        }
      } catch (err) {
        console.log(err);
      }
    } else if (method === "PATCH") {
      let Id = Number(QuoteValue.jobId);
      try {
        const response = await revisedSendQuoteAPI({ Id, method, paramsData });
        if (response.data) {
          revisedQuoteProceedFun();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function callApi(appState, methodVal, changeVal) {
    const nextQuoteState = getNextQuoteState(appState);
    console.log("nextQuoteState", nextQuoteState);
    let nextJobState = getNextJobState(appState);
    console.log("nextJobState", nextJobState);

    if(changeVal && quoteStatus.includes(QuoteState.Approved) ) {
      nextJobState = 'JobInspected';
    }

    if (nextJobState != null) {
      sendJobStatusAPI(nextJobState);
    }

    callQuoteCalcAPI(methodVal, nextQuoteState);
  }

  const [sendJobStatus, { data: responsedata2 }] = useUpdateJobStatusMutation();
  async function sendJobStatusAPI(str) {
    const paramsData = {
      status: str, //  job status
    };
    try {
      let Id = Number(QuoteValue?.jobId || QuoteValue.id);
      const response = await sendJobStatus({ Id, paramsData });
      if (response.data) {
        let updtJobStatus = response.data.status;
        console.log(updtJobStatus);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function confirmQuoteFunc() {
    setOpenPopup(false);
    SetPopupTitle("Done!");
    SetPopupContent(
      "The quotation has been shared with Home Owner successfully."
    );
    let btnValues = [
      {
        id: "1",
        value: "Close",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: redirectHomePageFun,
      },
    ];
    setButtonArr(btnValues);
    setOpenPopup(true);
  }

  function closeFunc() {
    setOpenPopup(false);
  }

  function redirectHomePageFun() {
    setOpenPopup(false);
    navigate("/plumber");
  }

  function yesFunc() {
    setOpenPopup(false);
    setEditQuote(true);
    setShowRevisedQuote(true);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }
  function quoteStatusFunc(status) {
    console.log("ramya qdbtn1", appState?.qdButton1);
    if (appState?.qdButton1 != null) {
      return (
        <Button
          name={appState.qdButton1}
          color="green"
          onClick={() =>
            btnEvntHandlerMap.get(appState.qdButton1)(appState.qdButton1)
          }
        />
      );
    }
  }

  return (
    <Grid>
      <Grid id="quoteCalc-tab">
        {editQuote && (
          <>
            <Grid padding="5%" pl={4}>
              <Typography fontSize="16px" fontWeight="bold">
                Add the labour cost
              </Typography>
            </Grid>

            <Grid padding="8%" pb={3} bgcolor="white">
              <div className="quoteLabelCls">Labour cost</div>
              <TextField
                handleChange={handleChange}
                formValues={formQuotesValues}
                textFieldStyle={{ width: "100%" }}
                listTXFL={labourCstFld}
                InputLabelProps={{ style: { fontSize: 40, paddingTop: 0 } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" size="small">
                      <AttachMoneyIcon
                        style={{
                          borderLeft: "1.5px solid #3C8C9E",
                          paddingLeft: "10px",
                        }}
                      ></AttachMoneyIcon>
                    </InputAdornment>
                  ),
                  style: { fontSize: 16 },
                }}
              />
              <Grid>
                <div className="quoteLabelCls">
                  Total time needed (Add hours)
                </div>
                <TextField
                  listTXFL={timeHrsFld}
                  handleChange={handleChange}
                  formValues={formQuotesValues}
                  textFieldStyle={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={6} pt={1} className="grid">
                <div className="checkboxDs">
                  <Checkbox
                    style={{ paddingLeft: "0px" }}
                    checked={showCallOutFreeField}
                    onChange={() => handleCalloutfeechanges()}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span>Add call out fee to labour cost</span>
                </div>
              </Grid>
              <br />
              {showCallOutFreeField && (
                <>
                  {" "}
                  <Grid>
                    <div className="quoteLabelCls">Call out fee</div>
                    <TextField
                      listTXFL={calloutFeeFld}
                      handleChange={handleChange}
                      formValues={formQuotesValues}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid sx={{ backgroundColor: "#F7F7F7", padding: "2%" }}>
              <Grid
                margin="8% 5%"
                borderRadius="6px"
                padding="6%"
                bgcolor="white"
              >
                <div className="viewPrductCls">View Products</div>
                <div className="viewPrdCntCls">
                  Refer third party service to get the parts price.
                </div>

                <div className="viewBtnCls">
                  <Button color="greenBorder" name="View Products" />
                </div>
              </Grid>
              <div className="viewPrductCls" style={{ paddingLeft: "20px" }}>
                Add items and pricing
              </div>
            </Grid>

            <Grid padding="8%" bgcolor="white">
              {generateAddPartsItem()}

              <br />

              <Grid margin="10% 0" display="flex" justifyContent="center">
                <Button
                  color="greenBorder"
                  name="+  Add New Item"
                  onClick={() => AddOnemoreItemsFunc()}
                />
              </Grid>
              <hr />
              <Grid
                mb={3}
                pt={1}
                pb={1}
                display="flex"
                justifyContent="space-between"
              >
                <div className="totalCls">
                  <b>TOTAL</b> Parts Estimate*
                </div>
                <div className="totalValueCls">
                  {totalPartsAmt ? "R " + totalPartsAmt : "-"}
                </div>
              </Grid>
            </Grid>
            <Grid padding="5%">
              <div className="noteCls">
                NOTE: *This value is not accurate, its for the parts price
                reference.
              </div>
            </Grid>
          </>
        )}

        <Grid sx={{ padding: "5%", paddingBottom: "15vh" }}>
          <TotalSummaryBox formValues={formQuotesValues}></TotalSummaryBox>
        </Grid>
        {!editQuote && (
          <>
            {paymentDetails ? (
              <Grid padding="5%" mt="-5%" onClick={() => openBtmSheetFun()}>
                <Grid
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  padding="5%"
                  bgcolor="white"
                  sx={{
                    color: "#E5F5E9",
                    background: " #E5F5E9 0% 0% no-repeat padding-box",
                    border: "0.3px solid #44983C",
                    borderRadius: "3px",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "2%",
                      paddingTop: "2%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="viewPayment">
                      View payment details (History)
                    </span>
                    <span>&emsp; &emsp;</span>

                    <ArrowForwardIosIcon style={{ color: "#0D5C75" }} />
                  </div>
                </Grid>
              </Grid>
            ) : null}

            <Grid padding="5%" mt="-5%">
              {" "}
              <Grid
                onClick={() => {
                  setShow(true);
                }}
                className="total-price-parts"
              >
                <span style={{ color: "#535353" }}>TOTAL Parts estimate *</span>
                <span style={{ color: "#0D5C75", fontWeight: "700" }}>
                  {totalPartsAmt ? "R  " + totalPartsAmt : " "}
                </span>
                <ArrowForwardIosIcon style={{ color: "#0D5C75" }} />
              </Grid>
            </Grid>
            <Grid padding="5%" mt="-5%">
              <div className="noteCls bgNoteCls">
                NOTE: *Parts cost is not added to the quote. It is listed only
                for informational purposes.
              </div>
            </Grid>
          </>
        )}

        <BottomSheet
          open={show}
          onDismiss={() => {
            setShow(false);
          }}
        >
          <Grid margin="5%">
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Parts Price / Items needed</Typography>
              <CloseIcon
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  color: "#0D5C75",
                }}
              />
            </Grid>
            <br />
            <Grid container>
              <Grid
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                padding="5%"
                bgcolor="#FCFCFC"
                box-shadow="0px 3px 6px #D9D9D929"
                border="0.5px solid #D9D9D929"
                borderRadius="5px"
              >
                <div
                  style={{
                    color: "#838383",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Items needed
                </div>
                <Grid container spacing={2} pt={2} pb={2}>
                  <Grid item xs={6}>
                    <span style={{ color: "#008EA1" }}>Items name</span>
                  </Grid>
                  <Grid item xs={3}>
                    <span style={{ color: "#008EA1" }}>Nos</span>
                  </Grid>
                  <Grid item xs={3}>
                    <span style={{ color: "#008EA1" }}>Price</span>
                  </Grid>
                  {QuoteValue?.parts?.items?.map((item, index) => {
                    return (
                      <>
                        <Grid item xs={6}>
                          <span className="summaryLabelCls">
                            {item?.itemName}
                          </span>
                        </Grid>
                        <Grid item xs={3}>
                          <span className="summaryLabelCls">{item?.count}</span>
                        </Grid>
                        <Grid item xs={3}>
                          <span className="summaryLabelCls">
                            R {item?.price}
                          </span>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>

                <Grid
                  id="jcsb"
                  pb={1.5}
                  pt={1.5}
                  borderTop="1px dashed #3C8C9E"
                  borderBottom="1px solid #3C8C9E"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL
                  </Typography>
                  <Typography
                    style={{
                      color: "#0D5C75",
                      fontWeight: "bold",
                    }}
                  >
                    R {totalPartsAmt}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BottomSheet>

        <DialogComp
          openPopup={openPopup}
          closeFunc={closeFunc}
          popuptitle={popuptitle}
          popupContent={popupContent}
          buttonValue={buttonArr}
        />
        <BottomSheet open={openBtmSheet}>
          <PaymentDetailsPg
            paymentValues={paymentDetails}
            closeBtmSheet={closeBtmSheet}
          />
        </BottomSheet>
      </Grid>

      <Grid
        display="flex"
        justifyContent="center"
        boxShadow="-42px -24px 42px #C4C4C464"
        bgcolor="#ffffff"
        padding="12px 20px 12px 20px"
        width="100%"
        style={{ position: "fixed", bottom: "0" }}
      >
        {showRevisedQuote ? (
          <Button
            color="green"
            name="Send Revised Quote"
            onClick={() => revisedQuoteFun()}
          />
        ) : (
          quoteStatusFunc()
        )}
      </Grid>
    </Grid>
  );
}
