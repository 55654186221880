import Select, { components } from "react-select";
import { Grid, Checkbox} from "@mui/material";
import shower from '../../assets/shower.jpg';
import React from "react";

let options = [
  { value: 'Bathroom fitting', label: 'Bathroom fitting', img: shower },
  { value: 'Kitchen sink', label: 'Kitchen sink', img: shower },
  { value: 'Motor Repair', label: 'Motor Repair', img: shower },
  { value: 'Pipe Leakage', label: 'Pipe Leakage', img: shower },
  { value: 'Shower', label: 'Shower', img: shower },
  { value: 'Water Tank', label: 'Water Tank', img: shower }
 
];

const style = {
  option: (provided, state) => ({
      ...provided,
      borderBottom: "none",
      color:"black",
      backgroundColor:"white",
  }),
  control: () => ({
      display: "flex",
      border: "1.5px solid #3c8c9e",
      backgroundColor: "none",
  }),
  input: (provided) => ({
      ...provided,
  }),
  valueContainer: (provided) => ({
      ...provided,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#0D5C75",
}),
};
const Option = props => {
  return (
      <components.Option {...props} >
        <Grid display='flex' sx={{ justifyContent: 'space-between'}}>
          <Grid display='flex'>
            <img style={{ height: '25px', width: '25px', paddingTop: '6px' }} src={props.data.img} alt='pic' />
            <div style={{ padding: '10px 0px 0px 8px'}}>{props.data.label}</div>
          </Grid>
                 <Checkbox checked={props.isSelected}/>   
        </Grid>
      </components.Option>
  );
};
export default function MultiSelectDropDown() {
  return (
    <div>
      <Select components={{ Option, IndicatorSeparator:() => null }}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={true}
        placeholder="Select skills"
        isClearable={false}
        styles={style}
        options={options}
        isSearchable={false} />
    </div>

  );
}
