import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComp from "../Button";
import "./styles.css";

export default function DialogComponent(props) {
  const { openPopup, popuptitle, popupContent, buttonValue } = props;
  let lengthOfBTN = buttonValue.length;

  return (
    <div>
      <Dialog
        open={openPopup}
        // maxWidth={'xs'}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="dialog-titleCls"
          style={{ fontWeight: "bold" }}
          sx={{
            letterSpacing: "0px",
            color: "#000000",
            fontSize: "20px",
          }}
        >
          {popuptitle}
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: "10px",
            fontSize: "14px",
            letterSpacing: "0px",
            color: "#000000",
            whiteSpace: "pre-line",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {popupContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: lengthOfBTN === 1 ? "flex-end" : "center",
            paddingBottom: "7%",
          }}
        >
          {buttonValue.length !== 0 &&
            buttonValue.map((item) => (
              <ButtonComp
                color={item.color}
                sx={{ fontWeight: item.fontWeight }}
                onClick={item.functionCall}
                name={item.value}
              ></ButtonComp>
            ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}
