import React from 'react';
import { bool } from 'prop-types';
import { Typography, Grid, Box, } from "@mui/material";
import { StyledMenu } from './Menu.styled';
import { Link  } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';
import image1 from "../../assets/img.jpg";
import image5 from "../../assets/About (2).png";
import image2 from "../../assets/history (1).jpg";
import image3 from "../../assets/Manage_Adddress (1).png";
import image4 from "../../assets/My_rating (2).png";
import setting from "../../assets/setting.png";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Menu = ({ open, ...props }) => {

  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  const navigate = useNavigate();

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <profilecard>
        <CardMedia sx={{ width: "4rem", marginTop: '3.7rem', height: '4rem', marginLeft: '1.7rem', borderRadius: '6px' }}
          component="img"

          image={image1}
          alt="green iguana"
        />

        {/* <img src={image1} style={{}} alt="hiii"
           
           /> */}
        <div style={{ marginTop: '4rem', marginLeft: '4%' }} >
          <Typography

            textAlign='left'
            margin='12px'
            fontSize="16px"
            fontFamily="Rubik"
            fontWeight="normal"

            color="#E5F5E9"
            letter-spacing="0.09px"
            opacity="1"
          >
            Hi, Name
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <EditIcon
              style={{

                marginTop: '5px',

                fontSize: '22',
                width: '19.05px',
                height: "19.05px"
              }}
              onClick={() => {
                navigate("/plumberDetails");
              }}

            />
            <span style={{ fontSize: "13px", marginLeft: '15px', fontWeight: "normal", color: "#E5F5E9", letterSpacing: "0.09px", marginTop: '4%' }}

            // ml='2px'
            //       paddingleft='9px'
            //       color="#E5F5E9"
            //       letter-spacing="0.09px"
            //       opacity="1"
            //       lineHeight='2.5'
            //       fontFamily="Rubik"
            //       fontWeight="Regular"
            >
              Edit Profile
            </span>
          </div>
        </div>
      </profilecard>
      <div style={{ backgroundColor: '#EAEAEA', height: '2.5rem' }}></div>
      <div style={{ display: 'flex', flexDirection: 'row', height: '9%', borderBottom: '2px solid #EAEAEA', alignItems: 'center' }}>
        {/* <a href="/" tabIndex={tabIndex}> */}
        <div style={{ textDecoration: 'none' }}
        onClick={() => {
          navigate("/allMatchingJobs",{state: 'NEW'} );
        }}
        >
          <img src={image2} style={{ width: '14px', height: '14px', marginLeft: '25px' }} alt="hiii"
          />

          {/* <span aria-hidden="true"></span> */}

          <span style={{
            marginLeft: '1rem', color: "#202121", fontSize: '15px', opacity: '1', letterSpacing: '0.09px', fontFamily: "Rubik",
            fontWeight: "normal"
          }}>Job History
          </span>

          <span>
            < ArrowForwardIosIcon
              style={{
                width: '32px',
                height: "18px",
                color: "#0D5C75",
                marginLeft: '6rem'
              }}

            />
          </span>

        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', height: '9%', borderBottom: '2px solid #EAEAEA', alignItems: 'center' }}>
        {/* <a href="/" tabIndex={tabIndex}> */}
        <Link to="/ManageAddress" style={{ textDecoration: 'none' }}>
          <img src={image3} style={{ width: '14px', height: '14px', marginLeft: '23px' }} alt="hiii"

          />

          {/* <span aria-hidden="true"></span> */}

          <span style={{
            marginLeft: '1rem', color: "#202121", fontSize: '15px', opacity: '1', letterSpacing: '0.09px', fontFamily: "Rubik",
            fontWeight: "normal"
          }}>Manage Address



          </span>

          <span>
            < ArrowForwardIosIcon
              style={{
                width: '33px',
                height: "18px",
                color: "#0D5C75",
                marginLeft: '4rem'
              }}

            />
          </span>

        </Link>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', height: '9%', borderBottom: '2px solid #EAEAEA', alignItems: 'center' }}>
        {/* <a href="/feedback" tabIndex={tabIndex}> */}
        <Link to="/ratings" style={{ textDecoration: 'none' }}>

          <img src={image4} style={{ width: '14px', height: '14px', marginLeft: '23px' }} alt="hiii"

          />

          {/* <span aria-hidden="true"></span> */}

          <span style={{
            marginLeft: '1rem', color: "#202121", fontSize: '15px', opacity: '1', letterSpacing: '0.09px', fontFamily: "Rubik",
            fontWeight: "normal"
          }}>My Rating



          </span>

          <span>
            < ArrowForwardIosIcon
              style={{
                width: '32px',
                height: "18px",
                color: "#0D5C75",
                marginLeft: '7rem'
              }}

            />
          </span>
        </Link>
        {/* </a> */}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', height: '9%', borderBottom: '2px solid #EAEAEA', alignItems: 'center' }}>
        {/* <a href="/" tabIndex={tabIndex}> */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src={image5} style={{ width: '14px', height: '14px', marginLeft: '23px' }} alt="hiii"

          />

          {/* <span aria-hidden="true"></span> */}

          <span style={{
            marginLeft: '1rem', color: "#202121", fontSize: '15px', opacity: '1', letterSpacing: '0.09px', fontFamily: "Rubik",
            fontWeight: "normal"
          }}>About DigiAqua



          </span>

          <span>
            < ArrowForwardIosIcon
              style={{
                height: "18px",
                color: "#0D5C75",
                marginLeft: '74px'
              }}

            />
          </span>

        </Link>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', height: '9%', borderBottom: '2px solid #EAEAEA', alignItems: 'center' }}>
        {/* <a href="/" tabIndex={tabIndex}> */}
        <Link to="/setting" style={{ textDecoration: 'none' }}>
          <img src={setting} style={{ width: '14px', height: '14px', marginLeft: '23px' }} alt="hiii"

          />

          {/* <span aria-hidden="true"></span> */}

          <span style={{
            marginLeft: '1rem', color: "#202121", fontSize: '15px', opacity: '1', letterSpacing: '0.09px', fontFamily: "Rubik",
            fontWeight: "normal"
          }}>Settings



          </span>

          <span>
            < ArrowForwardIosIcon
              style={{
                width: '45px',
                height: "18px",
                color: "#0D5C75",
                marginLeft: '7rem'
              }}

            />
          </span>

        </Link>
      </div>
      <span style={{ height: '10%', marginTop: '5%', marginLeft: '8%' }}>
        <div to="/" style={{
          color: '#0D5C75', fontFamily: "Rubik",
          fontSize: "20px", textDecoration: 'none'
        }}
          onClick={() => {
            navigate("/Login");
          }}
        >

          Logout
        </div>
        <Typography style={{
          color: '#202121', lineHeight: '55px', fontFamily: "Rubik",
          fontSize: "17px", fontWeight: '400'
        }}>Version V0.0</Typography>
      </span>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
