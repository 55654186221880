const JobState = {
  New: "New",
  Draft: "Draft",
  Assigned: "JobAssigned",
  Accepted: "JobAccepted",
  Rejected: "JobRejected",
  Approved: "JobApproved",
  Inspected: "JobInspected",
  Finalised: "JobFinalised",
  Started: "JobStarted",
  Completed: "JobCompleted",
  Paused: "JobPaused",
  Closed: "JobClosed",
  Settled: "JobSettled",
  Paid: "JobPaid",
  Rescheduled: "JobRescheduled",
  Cancelled: "JobCancelled",
};

const QuoteState = {
  Submitted: "QuoteSubmitted",
  Approved: "QuoteApproved",
  Rejected: "QuoteRejected",
  Withdrawn: "QuoteWithdrawn",
  Revised: "QuoteRevised",
};

const JobBTNs = {
  RespondToJob: "Respond to Job Request",
  Submit: "Submit Job",
  Close: "Close Job",
  Cancel: "Cancel Job",
  Reschedule: "Reschedule Job",
  Accept: "Accept Job",
  Reject: "Reject Job",
  Inspect: "Inspect Job",
  Inspected: "Job Inspected",
  Start: "Start Job",
  Complete: "Mark Job Completed",
  NA: "N.A",
};

const QuoteBTNs = {
  Submit: "Submit Quote",
  Approve: "Approve Quote",
  Reject: "Reject Quote",
  Edit: "Edit Quote",
  Revise: "Send Revise Quote",
  PayNow: "Pay Now",
  NA: "N.A",
};

// const Action = {
//     SubmitJob: "SubmitJob",
//     SubmitQuote: "SubmitQuote",
//     ApproveQuote: "ApproveQuote",
//     RejectQuote: "RejectQuote",
//     CancelJob: "CancelJob",
//     RescheduleJob: "RescheduleJob",
//     ReviseQuote: "ReviseQuote",
//     ApproveJob: "ApproveJob",
//     RejectJob: "RejectJob",
//     InspectedJob: "InspectedJob",
//     StartJob: "StartJob",
//     CompleteJob: "CompleteJob",
//     CloseJob: "CloseJob",
//     PayNow: "PayNow"

// }

class AppState {
  constructor(
    ipJobState,
    ipQuoteState,
    jdButton1,
    jdButton2,
    qdButton1,
    qdButton2,
    action,
    opJobState,
    opQuoteState
  ) {
    this.ipJobState = ipJobState;
    this.ipQuoteState = ipQuoteState;

    this.jdButton1 = jdButton1;
    this.jdButton2 = jdButton2;

    this.qdButton1 = qdButton1;
    this.qdButton2 = qdButton2;

    this.action = action;
    this.opJobState = opJobState;
    this.opQuoteState = opQuoteState;
  }
}

var hoStateArr = [
  new AppState(
    JobState.New,
    null,
    null,
    null,
    QuoteBTNs.Submit,
    null,
    QuoteBTNs.Submit,
    null,
    QuoteState.Submitted
  ),
  new AppState(
    JobState.Assigned,
    QuoteState.Approved,
    JobBTNs.Reject,
    JobBTNs.Accept,
    null,
    null,
    JobBTNs.Accept,
    JobState.Accepted,
    null
  ),
  new AppState(
    JobState.Assigned,
    QuoteState.Approved,
    JobBTNs.Reject,
    JobBTNs.Accept,
    null,
    null,
    JobBTNs.Reject,
    JobState.New,
    QuoteState.Withdrawn
  ),
  new AppState(
    JobState.New,
    QuoteState.Rejected,
    null,
    null,
    QuoteBTNs.Edit,
    null,
    JobBTNs.Edit,
    JobState.New,
    QuoteState.Revised
  ),
  //Inspect Job
  new AppState(
    JobState.Accepted,
    QuoteState.Approved,
    null,
    JobBTNs.Inspect,
    null,
    null,
    JobBTNs.Inspect,
    JobState.Finalised,
    null
  ),
  //Start Job
  new AppState(
    JobState.Finalised,
    QuoteState.Approved,
    null,
    JobBTNs.Start,
    null,
    null,
    JobBTNs.Start,
    JobState.Started,
    null
  ),
  //Complete Job
  new AppState(
    JobState.Started,
    QuoteState.Approved,
    null,
    JobBTNs.Complete,
    null,
    null,
    JobBTNs.Complete,
    JobState.Completed,
    null
  ),
];

function getAppState(ipJobState, ipQuoteState) {
  for (const appState of hoStateArr) {
    // console.log("appState", appState);
    if (
      appState.ipJobState === ipJobState &&
      appState.ipQuoteState === ipQuoteState
    ) {
      return structuredClone(appState);
    }
  }
  return null;
}

function getNextJobState(ipAppState) {
  for (const appState of hoStateArr) {
    if (
      appState.ipJobState === ipAppState.ipJobState &&
      appState.ipQuoteState === ipAppState.ipQuoteState &&
      appState.action === ipAppState.action
    ) {
      return appState.opJobState;
    }
  }
  return null;
}

function getNextQuoteState(ipAppState) {
  for (const appState of hoStateArr) {
    if (
      appState.ipJobState === ipAppState.ipJobState &&
      appState.ipQuoteState === ipAppState.ipQuoteState &&
      appState.action === ipAppState.action
    ) {
      return appState.opQuoteState;
    }
  }
  return null;
}

export {
  getAppState,
  getNextJobState,
  getNextQuoteState,
  QuoteState,
  QuoteBTNs,
  JobBTNs,
  JobState,
};
