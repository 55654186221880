import { React, useState } from "react";
import { Typography, Grid, FormControl, TextField, Checkbox } from "@mui/material";
import Button from "../../components/Button"
import StepperComp from "./StepperComp";
import DialogComponent from '../../components/Dialog-Popup'
import { useNavigate } from "react-router-dom";

import "./style.css";
import UploadQualificationDocs from "./UploadqualificationDocs";


export default function PlumberDetailsNext() {
    const [openPopup, setOpenPopup] = useState(false);
    const [popuptitle, SetPopupTitle] = useState("");
    const [popupContent, SetPopupContent] = useState("");
    const [buttonArr, setButtonArr] = useState([]);
    const navigate = useNavigate();

    const handleClickOkay = () => {
        setOpenPopup(false);
        localStorage.setItem('progress', true);
        navigate("/Plumber")
    }

    const handlePopupClick = () => {
        setOpenPopup(true);
        SetPopupTitle("You're almost done !");
        SetPopupContent(
            "You have successfully completed the registration process.\n You can start work as a registered Service provider once you receive the verification confirmation from WHC admin."
        );
        let btnValues = [
            {
                id: "1",
                value: "Ok",
                fontWeight: "bold",
                color: "borderLess",
                showCondition: "true",
                functionCall: handleClickOkay
            }
        ];
        setButtonArr(btnValues);
    }

    return (
        <Grid container backgroundColor='#F4F4F4' height='100%' className='main-container'>
            <Grid container display='flex' backgroundColor='#F4F4F4' height='10%' style={{ alignItems: 'center', padding: '15px 90px 35px 90px', position: 'sticky', top: 0, zIndex: 1 }}>
                <StepperComp active={2} steps={['step 1', 'Step 2']} />
            </Grid>
            <Grid container display='flex' height='8%' padding='15px' style={{ justifyContent: 'space-between' }}>
                <Typography style={{fontWeight:'600'}} fontSize='20px'>Tell us more about you!</Typography>
                <Typography>Step 2</Typography></Grid>
            <Grid padding="7%" container backgroundColor='#FFFFFF' marginBottom="27px">
                <FormControl size="small" fullWidth>
                    <Grid item className="">
                        <div className="addressCls">National ID</div>
                    </Grid>
                    <Grid item sm={6} pt={3} className="grid">
                        <label htmlFor="name" className="labelCls2">
                            What is your National ID
                        </label>
                        <TextField size="small" placeholder="Add your national ID" fullWidth m="2" />
                    </Grid>
                    <UploadQualificationDocs name="Upload ID proof" />
                </FormControl>
            </Grid>

            <Grid padding="7%" container backgroundColor='#FFFFFF'>
                <FormControl size="small" fullWidth>
                    <Grid item className="">
                        <div className="addressCls">Payment Details</div>
                        <div style={{ fontSize: '12px', marginTop: '12px', fontWeight: '500' }}>Add all banking details for payment transactions</div>
                    </Grid>

                    <Grid item sm={6} pt={1} className="grid">
                        <label htmlFor="name" className="labelCls2">
                            Account Number
                        </label>
                        <TextField
                            size="small"
                            placeholder="Add account number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} pt={3} className="grid">
                        <label htmlFor="name" className="labelCls2">
                            Account Holder's name
                        </label>
                        <TextField size="small" placeholder="Add account holder's name" fullWidth />
                    </Grid>
                    <Grid item sm={6} pt={3} className="grid">
                        <label htmlFor="Province" className="labelCls2">
                            Bank Branch name
                        </label>
                        <TextField size="small" placeholder="Add bank branch name" fullWidth />
                    </Grid>

                </FormControl>
            </Grid>
            <Grid display="flex" container backgroundColor='#F4F4F4' padding='11px'>
                <Checkbox sx={{ paddingLeft: '15px' }}
                    inputProps={{ "aria-label": "controlled" }}
                />
                <Grid display="flex" flexDirection="column" style={{margin:'3% 0%'}}>
                    <Grid>By proceeding, you agree to DigiAqua's</Grid>
                    <Grid style={{ padding: '5px 0px' }}><a href="" style={{ color: '#0D5C75' }}>Terms & Conditions</a> and <a href="" style={{ color: '#0D5C75' }}>Privacy policy</a></Grid>
                </Grid>

            </Grid>
            <DialogComponent
                openPopup={openPopup}
                popuptitle={popuptitle}
                popupContent={popupContent}
                buttonValue={buttonArr}
            />
            <Grid display="flex" flexDirection="row" alignItems="center" justifyContent="center" bgcolor="#ffffff" padding="12px 20px 12px 20px" width="100%" sx={{ position: 'sticky', bottom: 0, boxShadow: 8 }}>
                <Button
                    color="green"
                    name="Save Details"
                    onClick={handlePopupClick}
                />
            </Grid>
        </Grid>
    );
}
