import React from "react";
import "./styles.css";
import { Grid, Typography } from "@mui/material";
import "react-spring-bottom-sheet/dist/style.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ManageAddressdata } from "../../assets/manageAddressdata";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { BottomSheet } from "react-spring-bottom-sheet";

import NewAddressPage from "../DateTime/newAddressPage";

function ManageAddress() {
  const [openAddressBottom, setOpenAddressBottom] = React.useState(false);
  const [data, setData] = React.useState(ManageAddressdata);
  // const [addresspagedata, setAddresspagedata] = React.useState("");
  const [formValues, setFormValues] = React.useState();
  const navigate = useNavigate();

  function editchange(index) {
    console.log("index", index, data);

    const editdata = data.find((x) => x.addressId == index);
    console.log("-edit data--", editdata);
    setOpenAddressBottom(true);
    setFormValues(editdata);
    console.log(editdata);
  }
  function openAddressFunc() {
    setFormValues({
      addressId: 0,
      name: "",
      contact: "",
      houseNo: "",
      landmark: "",
      street: "",
      city: "",
      province: "",
    });
    setOpenAddressBottom(true);
  }

  function closeAddressFunc() {
    setOpenAddressBottom(false);
  }
  const deleteItem = (index) => {
    const updatedArr = data.filter((item, j) => {
      return j != index;
    });
    setData(updatedArr);
  };
  return (
    <Grid container>
      <Grid
        style={{
          display: "flex",
          flexDirection: "start",
          padding: "1rem",
          position: "sticky",
          top: "0",
          backgroundColor: "#F2F2F2",
          width: "100%",
          zIndex: "1",
        }}
      >
        <ArrowBackIcon
          onClick={() => {
            navigate("/Plumber");
          }}
          style={{
            fontSize: "31",
            color: "#0d4f57",
            paddingLeft: "4%",
            justifyContent: "left",
          }}
        />
        <Typography
          pl={2}
          style={{
            fontSize: "17px",
            fontWeight: "600",
            letterSpacing: "1",
            color: "#535353",
            paddingLeft: "15px",
          }}
        >
          Manage Address
        </Typography>
      </Grid>

      <Grid display="flex" flexDirection="column" className="manageaddress">
        {data.map((obj, index) => {
          return (
            <>
              <Grid
                display="flex"
                id="jcsb"
                style={{
                  // position: "absolute",

                  right: "6px",
                  paddingTop: "2%",
                }}
              >
                {" "}
                <Typography
                  pb={1}
                  width="100%"
                  id="two-lines-text-css"
                  // borderBottom="1px solid #0D5C75"
                  style={{
                    fontSize: "12px",
                    color: "#535353",
                    paddingLeft: "3%",
                    fontFamily: 'Rubik',
                    fontWeight: 'normal'
                  }}
                >
                  {obj.title}
                </Typography>
                <EditIcon
                  style={{
                    marginLeft: "17px",
                    fontSize: "22",
                    color: "#0D5C75",
                    height: "1.8rem",
                  }}
                  onClick={() => {
                    editchange(obj.addressId);
                  }}
                />
                <DeleteOutlineIcon
                  style={{
                    paddingRight: "4%",
                    fontSize: "29",
                    color: "#0D5C75",
                  }}
                  onClick={() => {
                    deleteItem(index);
                  }}
                />
              </Grid>{" "}
              <div className="box-manageAddress" style={{ width: "95%" }}>
                <div className="addresslist">
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: 'Rubik',
                      fontWeight: 'normal'
                    }}
                  >
                    {obj.name}
                  </Typography>
                  <Typography
                    pt={1}
                    variant="subtitle2"
                    style={{ color: "#535353" }}
                  >
                    {"#"}
                    {obj.houseNo}
                    {","}
                    {obj.landmark}
                  </Typography>
                  <Typography
                    pt={1}
                    variant="subtitle2"
                    style={{
                      color: "#535353",
                      fontFamily: 'Rubik',
                      fontWeight: 'normal'
                    }}
                  >
                    {obj.city}
                    {","}
                    {obj.province}
                  </Typography>
                  <Typography
                    pt={1}
                    variant="subtitle2"
                    style={{
                      color: "#535353",
                      top: "339px",
                      left: "48px",
                      width: "205px",
                      fontFamily: 'Rubik',
                      fontWeight: 'normal',
                      height: "14px",
                    }}
                  >
                    {obj.street} {obj.landmark}
                  </Typography>
                </div>
                <div></div>
              </div>
            </>
          );
        })}
        <BottomSheet
          snapPoints={({ minHeight }) => [800]}
          open={openAddressBottom}
          onDismiss={closeAddressFunc}
        >
          <NewAddressPage
            closeAddressFunc={closeAddressFunc}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </BottomSheet>
      </Grid>
      <Grid
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        padding="9% 12px 22% 12px"
        width="100%"
      >
        <Button
          color="white"
          name="Add a new Address"
          onClick={() => {
            openAddressFunc();
          }}
        ></Button>
      </Grid>
      <Grid
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        bgcolor="#ffffff"
        padding="12px 20px 12px 20px"
        width="100%"
        sx={{ position: "fixed", bottom: 0, boxShadow: 8 }}
      >
        <Button color="green" name="Save Details"></Button>
      </Grid>
    </Grid>
  );
}

export default ManageAddress;
