import baseAPI from "../app/baseAPI";

export const ProductReducer = baseAPI.injectEndpoints({
  endpoints(builder) {
    return {
      fetchProductDetails: builder.query({
        query() {
          return {
            url: `http://localhost:3000/products`,
            method: "GET",
            token: true,
          };
        },
        keepUnusedDataFor: 5,
        transformResponse: (responseData) => responseData,
      }),
    };
  },
});
export const {
  useFetchProductDetailsQuery

} = ProductReducer;
