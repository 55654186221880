// https://youtu.be/9e-5QHpadi0 - basic
// https://youtu.be/WZcxJGmLbSo - places api

import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  InfoBox,
} from "@react-google-maps/api";
import MarkerIcon from "../assets/water marker.svg";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

function Map(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // const center = React.useMemo(
  //   () => ({
  //     lat: props.geolat,
  //     lng: props.geolog,
  //   }),
  //   []
  // );

  const center = {
    lat: props.geolat,
    lng: props.geolog,
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  function handleClick(event) {
    // const lat = event.latLng.lat(),
    // const lng = event.latLng.lng();
    console.log(
      "### Marker location - ",
      event.latLng.lat(),
      event.latLng.lng()
    );
  }
  return isLoaded ? (
    <GoogleMap
      onClick={(e) => handleClick(e)}
      // mapContainerClassName=""
      mapContainerStyle={containerStyle}
      center={center} // either useMemo for center or define center outside the function
      // center={{ lat: 20.5937, lng: 78.9629 }} // dont use this bcz it will keep re-rendering
      zoom={12}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        <Marker
          name="User Location"
          position={{ lat: props.geolat, lng: props.geolog }}
        >
          <InfoWindow>
            <span>You are here</span>
          </InfoWindow>
        </Marker>

        <Marker
          name="Marker location"
          // title="Drag & click to get Lat & lng" // label="Drag & click to getLat & lng"
          icon={MarkerIcon}
          position={{ lat: 28.69, lng: 77.09 }}
          draggable={true}
          onMouseUp={(e) => handleClick(e)}
          onClick={(e) => handleClick(e)}
        >
          <InfoWindow>
            <span>Drop the pin to destination</span>
          </InfoWindow>
        </Marker>
      </>
    </GoogleMap>
  ) : null;
}

export default Map;
