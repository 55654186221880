import { Navigate, Route, Routes } from "react-router-dom";
import SplashScreen from "./screens/SplashScreen";
import Plumber from "./screens/Plumber";
import AllMatchingJobs from "./screens/AllMatchingJobs";
import CurrentLoc from "./components/currentLoc";
import JobDetailsPage from "./screens/JobDetailPage";
import JobQuotesPage from "./screens/JobQuoteCalcPage";
import UploadInvoice from "./screens/UploadInvoice";
import UploadImages from "./screens/UploadImages";
import Feedback from './screens/Feedback';

import PlumberDetails from "./screens/LandingScreens/PlumberDetails";
import PlumberDetailsNext from "./screens/LandingScreens/PlumberDetailsNext";
import Setting from "./screens/Setting";
import Ratings from "./screens/Ratings";
import Login from "./screens/Login/Login";
import ManageAddress from "./screens/ManageAddress/ManageAddress";
export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/Plumber" />} />
      <Route path="/index.html" element={<Navigate replace to="/Plumber" />} />
      <Route path="/splash" element={<SplashScreen />} />
      <Route path="/plumber" element={<Plumber />} />
      <Route path="/map" element={<CurrentLoc />} />
      <Route path="/allMatchingJobs" element={<AllMatchingJobs />} />
      <Route path="/jobDetails" element={<JobDetailsPage />} />
      <Route path="/Job-quoteCal" element={<JobQuotesPage />} />
      <Route path="/uploadInvoice" element={<UploadInvoice />} />
      <Route path="/uploadImages" element={<UploadImages />} />
      <Route path="/feedback" element={<Feedback />} />
      
      
      <Route path="/plumberDetails" element={<PlumberDetails />} />
      <Route path="/plumberDetailsNext" element={<PlumberDetailsNext />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/ratings" element={<Ratings />} />
      <Route path="/login" element={<Login />} />
      <Route path="/ManageAddress" element={<ManageAddress/>} />
    </Routes>
  );
}
