import React from "react";
import ReactStars from 'react-stars'
import './styles.css';


function StarRating({count=5,value,size=26,color1,color2, edit='true'}) {
  const style={
    color: '#0D5C75',
    border:'1px solid red'
  }

  const ratingChanged = (newRating) => {
    console.log(newRating)
  }
  return (
    <div className="AppStar">
      <ReactStars count={count} value={value} edit={edit} onChange={ratingChanged} size={size} color={'red'} className={style} color2={'#0D5C75'} half={true} char='★'/>
    </div>
  );
}

export default StarRating;
