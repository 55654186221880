import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import Button from "../../components/Button";
import "./styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { FromCamera } from "../../components/camera";
import { useNavigate } from "react-router-dom";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import DialogComp from "../../components/Dialog-Popup";

export default function UploadImages() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0); // state change to refresh UI
  const [blobUrl, setBlobUrl] = useState(null);
  const [imgArr, setImgArr] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popuptitle, SetPopupTitle] = useState("");
  const [popupContent, SetPopupContent] = useState("");
  const [buttonArr, setButtonArr] = useState([]);

  function ThankyouPopup() {
    setOpenPopup(true);
    SetPopupTitle("Thank You!");
    SetPopupContent(
      "Thank you for completing the job. \n You will receive the payment for this job within 15 days."
    );
    let btnValues = [
      {
        id: "1",
        value: "CONTINUE",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: FeedbackPopup,
      },
    ];
    setButtonArr(btnValues);
  }

  function FeedbackPopup() {
    setOpenPopup(false);
    SetPopupTitle("Your Feedback matters");
    SetPopupContent("Please take some time to Rate & Review the Home Owner.");
    let btnValues = [
      {
        id: "1",
        value: "ADD FEEDBACK",
        fontWeight: "bold",
        color: "borderLess",
        functionCall: feedBackRedirection, // add navigation to feedback page
      },
      {
        id: "2",
        value: "LATER",
        fontWeight: "400",
        color: "borderLess",
        functionCall: HomepageRedirection,
      },
    ];
    setButtonArr(btnValues);
    setOpenPopup(true);
  }

  function feedBackRedirection() {
    setOpenPopup(false);
    navigate("/feedback");
  }
  function HomepageRedirection() {
    setOpenPopup(false);
    navigate("/plumber");
  }

  function closeFunc() {
    setOpenPopup(false);
  }

  function getBlobUrl() {
    let tempBlobObj = FromCamera();
    tempBlobObj
      .then((blob) => {
        imgArr.push(blob);
        setCount(count + 1); // refresh UI
        var fileFromBlob = new File([blob], "filename", {
          type: blob.type,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const onChange = (e) => {
    const files = e.target.files;
    imgArr.push(URL.createObjectURL(files[0]));
    setCount(count + 1); // a state needs to be changed to refresh UI
    console.log("###", imgArr);
    // e.target.value = "";
  };
  function refreshArr(index) {
    let remoteNamex;
    // remove from an array on the basis of index
    const updatedArr = imgArr.filter((item, j) => {
      remoteNamex = item.remoteName;
      return j != index;
    });
    setImgArr(updatedArr);
    // remove from an array on the basis of value
    // const updatedArr1 = ARRAYNAME.filter((item, j) => {
    //   return remoteNamex != item.remoteName;
    // });
    //  setImageArray(updatedArr1);
  }
  return (
    <>
      <Grid m="5%" className="upload-image-container">
        <Grid display="flex" alignItems="center" mt={2} pb={2}>
          <HomeOutlinedIcon
            style={{
              fontSize: "31",
              color: "#0d4f57",
            }}
            onClick={() => {
              navigate("/plumber");
            }}
          />{" "}
          <ArrowBackIcon
            onClick={() => {
              navigate(-1);
            }}
            style={{
              fontSize: "31",
              color: "#0d4f57",
              paddingLeft: "4%",
            }}
          />{" "}
          <Typography
            pl={2}
            style={{
              fontSize: "16px",
            }}
          >
            Job no #1220
          </Typography>
        </Grid>
        <h4
          style={{
            color: "#0d4f57",
          }}
        >
          Upload Images
        </h4>
        <Typography style={{ fontSize: "16px" }}>
          Please upload completed job images, so that we can mark the job status
          to completed.
        </Typography>
        <Typography style={{ fontSize: "13px" }}>
          (Max 4 Nos and image size should be less than X kb)
        </Typography>
        {localStorage.getItem("Platform") == "web" ? (
          <>
            <Grid
              m={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <label htmlFor="uploadDoc_button">
                <div className="upload-invoices-btn">
                  <text>Upload Images</text>
                </div>
              </label>
              <input
                id="uploadDoc_button"
                // disabled={disabled}
                accept="image/*"
                type="file"
                name="file"
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid
              m={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                mb={2}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Upload Images
              </Typography>
              <Grid id="icon-div">
                <PhotoCameraOutlinedIcon
                  id="camera-icon"
                  style={{
                    color: "#0D5C75",
                    fontSize: "35",
                  }}
                  onClick={getBlobUrl}
                />
                <Typography>Camera</Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container display="flex" flexDirection="row" flexWrap="wrap">
          {imgArr.map((obj, i) => {
            return (
              <Grid
                key={i}
                height="9vh"
                width="9vh"
                position="relative"
                item
                mr={1.4}
                mb={1.5}
              >
                {" "}
                <CloseIcon
                  onClick={() => refreshArr(i)}
                  style={{
                    padding: "3px",
                    borderRadius: "100px",
                    background: "white",
                    color: "#0d4f57",
                    position: "absolute",
                    fontSize: "18",
                    right: "0",
                    zIndex: "5",
                    border: "0.2px solid rgb(221, 221, 221)",
                  }}
                />
                <img
                  onClick={() => {
                    setBlobUrl(obj);
                    setOpen(true);
                  }}
                  alt="uploaded img"
                  className="upload-images-img"
                  src={obj}
                  // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3zpBaCu7jV3cbKHxgssz026Ba98wfoErmti_4n7oH&s"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        width="100%"
        position="absolute"
        bottom="9vh"
        pt={2}
        pb={2}
        display="flex"
        justifyContent="center"
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textDecoration: "underline",
            color: "#0d4f57",
          }}
          onClick={() => ThankyouPopup()}
        >
          Skip
        </Typography>
      </Grid>

      <Grid
        position="absolute"
        bottom="0"
        pt={2}
        pb={2}
        width="100%"
        display="flex"
        justifyContent="center"
        boxShadow="-42px -24px 42px #C4C4C464"
      >
        <Button
          onClick={ThankyouPopup}
          name="Confirm Completion"
          color="green"
        />
      </Grid>
      <Modal
        open={open}
        // onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <Box
        // position="relative"
        >
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            style={{
              padding: "5px",
              borderRadius: "100px",
              background: "white",
              color: "#0d4f57",
              position: "absolute",
              fontSize: "22",
              right: "3%",
              top: "3%",
              zIndex: "5",
              border: "1px solid #0d4f57",
            }}
          />
          <img
            width={window.innerWidth}
            // className="img-popUp"
            src={blobUrl}
            alt="uploaded image"
          />
        </Box>
      </Modal>
      <DialogComp
        openPopup={openPopup}
        closeFunc={closeFunc}
        popuptitle={popuptitle}
        popupContent={popupContent}
        buttonValue={buttonArr}
      />
    </>
  );
}
